import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { UPDATE_QUESTIONAIRE_CONSENT } from "./actionsType"
import { updateQuestionaireConsentSuccessful, updateQuestionaireConsentFailed } from "./actions"
import { apiError } from "store/actions"

import * as backend from "helpers/backend"

function* updateQuestionaireConsent({ payload: { questionData } }) {
    console.log('questionData', questionData)
    try {
        const response = yield call(backend.updateQConsent, questionData);
        console.log(response);
        const insertData = response.data
        yield put(updateQuestionaireConsentSuccessful(insertData))
    } catch (error) {
        console.log('error',error)
        if(typeof error.response.data !== "undefined") {
            yield put(updateQuestionaireConsentFailed(error.response.data.message))
            yield put(apiError(error.response.data.message));
        }
        else {
            yield put(updateQuestionaireConsentFailed(error.response))
            yield put(apiError(error.response));
        }
    }
}

export function* watchCreateQuestionaireQuestion() {
    yield takeEvery(UPDATE_QUESTIONAIRE_CONSENT, updateQuestionaireConsent)
}

function* questionaireConsentSaga() {
    yield all([fork(watchCreateQuestionaireQuestion)])
}

export default questionaireConsentSaga