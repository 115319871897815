// import { GetProfileView } from 'services/Api/Module/Profile';
import { persist } from 'zustand/middleware'
import { create } from 'zustand';

const defaultStep1 = {}
const defaultStep2 = {}

const useQuestionnaireStore = create(
  // persist(
    (set) => ({
        isLoaded: false,
        step1: defaultStep1,
        step2: defaultStep2,
        questionSet: [],
        form1: null,
        form2: null,

        setStep1: (payload = { step1: {} })=> {
          set({ isLoaded: true,  step1: payload.step1 });
        },
        setStep2: (payload = { step2: {} })=> {
          set({ step2: payload.step2 });
        },
        setQuestionSet: (payload = [] )=> {
          set({ questionSet: payload });
        },
        setForm1: (payload = null)=> {
          set({ form1: payload });
        },
        setForm2: (payload = null)=> {
          set({ form2: payload });
        },
        resetQuestionnaire: (payload = { step1: {}, step2: {} })=> {
          set({ isLoaded: false, step1: defaultStep1, step2: defaultStep2, questionSet: [] });
        },
      }
    ),
    // {
    //   name: 'questionnaire-storage3'
    // }
  // )
);

export default useQuestionnaireStore;