// import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Col, Row, Input, Form, FormFeedback, Button, Spinner, Table } from "reactstrap";
import { Label, InputGroup, Card, CardBody, Tooltip } from "reactstrap";
import * as Yup from "yup";
import { useFormik, FieldArray, FormikProvider } from "formik";
import Select from "react-select"
import { withTranslation } from "react-i18next";
import '../../components/Common/ts-css.css'
import fn_helper from "helpers/fn_helper";
import useQuestionnaireStore from "../../zustand/store/QuestionnaireModule";


const QuizChoice = ({
    typeForm = 'multi',
    onSubmit = () => console.log('onSubmit!!')
}) => {

    // CENTER STATE
    // const { questionSet = [] } = useQuestionnaireStore(state => state); // STATE
    const { setForm2 } = useQuestionnaireStore(); // ACTION

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            question_text: '',
            ans_choice_single: '0',
            ai_addon_questions: false,
            ask_number_question: '0',
            
            choice_list: [
                { is_ans : true, text: '' }
            ],

        },
        validationSchema: Yup.object({
            question_text: Yup.string().max(512).required("Please Enter Question"),
            // ans_choice_single: typeForm === 'multi' ? Yup.string() : Yup.string().required("Please Ans Choice Single"),
            ai_addon_questions: Yup.string(),
            ask_number_question: Yup.string(),

            choice_list: Yup.array().of(
                Yup.object().shape({
                    text: Yup.string().max(100).required("Please Enter Text"),
                    
                })
            ),
        }),
        onSubmit: (values) => {
            values.question_type = typeForm
            if (typeForm === 'multi') {
                const checkAns = validation.values.choice_list.map(item => item.is_ans)
                const isHasTrue = checkAns.some(item => item === true)
                if (isHasTrue === false) {
                    alert('Please select at least one answer.')
                    return
                }
                values.ans_choice_single = ''
            } 
            else if (typeForm === 'single') {
                if (!values.ans_choice_single) {
                    alert('Please select at one answer.')
                    return
                }
                values.choice_list = values.choice_list.map(item => {
                    delete item.is_ans
                    return item
                })
            }
            if (values.ai_addon_questions === false) {
                values.ai_addon_questions = false
                values.ask_number_question = 0
            }
            console.log('values >>', values)
            onSubmit(values)
        }
    })



    // Custom handleChange function
    const customHandleChange = (e, index) => {
        const { name, value } = e.target;

        // Example: Perform custom logic, e.g., transforming value
        let transformedValue = value; // Example transformation

        // Update Formik's field value with transformed value
        validation.setFieldValue(name, transformedValue);

        // Call Formik's handleChange
        validation.handleChange(e);
    };

    useEffect(() => {
        console.log("🚀 ~ useEffect ~ validation: form2 A", validation)
        setForm2(validation)
    }, [])

    const ddSelect = [...Array(6)].map((item, i) => ({ id: `${i}`, name: `${i}` }))

    return(
        <>
            <FormikProvider value={validation}>
                <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    {/* Question */}
                    <Row className="mb-3">
                        <Col>
                            <Label
                                htmlFor="example-search-input"
                                className="col-form-label"
                                >
                                <h5>Question:</h5>
                            </Label>
                            <div>
                                <Input
                                    id="question_text"
                                    className="form-control"
                                    type="text"
                                    placeholder="eg. What do you do?"
                                    name="question_text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.question_text}
                                    invalid={
                                        validation.touched.question_text && validation.errors.question_text ? true : false
                                    }
                                />
                                {validation.touched.question_text && validation.errors.question_text ? (
                                    <FormFeedback type="invalid">{validation.errors.question_text}</FormFeedback>
                                ) : null}
                                
                            </div>

                        </Col>
                    </Row>
                    {/* {JSON.stringify(validation.values.choice_list)} */}
                    {/* {
                        validation.touched.ans_choice_single && validation.errors.ans_choice_single ? (
                            <div type="invalid" className="invalid-feedback" style={{ display: 'block'}}>{validation.errors.ans_choice_single}</div>
                        ) : null
                    } */}
                    <Row className="mb-3">
                        <Col>
                            <FieldArray name="choice_list">
                                {({ push, remove }) => (
                                    <>
                                    {validation.values.choice_list.map((choice, index, arr = []) => (
                                        <Row key={index} className="mb-3">
                                            <Col md={10}>
                                                <InputGroup>
                                                    <span className="input-group-text">
                                                        {
                                                            typeForm === 'multi'
                                                            ? (
                                                                <Input
                                                                    className="form-check-input pl-5"
                                                                    id={`choice_list.${index}.is_ans`}
                                                                    name={`choice_list.${index}.is_ans`}
                                                                    type="checkbox"
                                                                    checked={choice?.is_ans}
                                                                    onClick={(e)=> {
                                                                        const value = validation.values.choice_list[index].is_ans
                                                                        const name = `choice_list.${index}.is_ans`
                                                                        validation.setFieldValue(name, !value);
                                                                    }}
                                                                    readOnly
                                                                />
                                                            )
                                                            : (
                                                                <Input
                                                                    className="form-check-input"
                                                                    id="ans_choice_single"
                                                                    name="ans_choice_single"
                                                                    type="radio"
                                                                    checked={validation.values.ans_choice_single === `${index}`}
                                                                    onClick={(e)=> {
                                                                        const value = `${index}`
                                                                        validation.setFieldValue('ans_choice_single', value);
                                                                    }}
                                                                    readOnly
                                                                />
                                                            )
                                                        }
                                                    </span>
                                                    <Input
                                                        id={`choice_list.${index}.text`}
                                                        name={`choice_list.${index}.text`}
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="eg. Eating"
                                                        onChange={(e) => customHandleChange(e, index)}
                                                        onBlur={validation.handleBlur}
                                                        value={choice.text}
                                                        invalid={!!(validation.touched.choice_list?.[index]?.text && validation.errors.choice_list?.[index]?.text)}
                                                    />
                                                    {
                                                        !!(validation.touched.choice_list?.[index]?.text && validation.errors.choice_list?.[index]?.text) ? (
                                                        <FormFeedback type="invalid"> {validation.errors.choice_list[index].text}</FormFeedback>
                                                        ) : null
                                                    }
                                                </InputGroup>
                                            </Col>
                                            <Col md={2} className="d-flex" style={{ padding: 0, alignItems:'baseline !important' }} >
                                            {
                                                arr.length > 1
                                                ? (
                                                    <Button
                                                        color="danger"
                                                        type="button"
                                                        style={{ height: '36px'}}
                                                        onClick={() => remove(index)} // Correctly removes the banner at this index
                                                    >
                                                        Remove
                                                    </Button>
                                                )
                                                : <></>
                                            }
                                            </Col>
                                        </Row>
                                    ))}
                                    <Button
                                        color="primary"
                                        type="button"
                                        onClick={() =>
                                            push({ is_ans: false, text: "" })
                                        }
                                    >
                                        Add Choice
                                    </Button>
                                    </>
                                )}
                            </FieldArray>
                        </Col>
                    </Row>

                    

                    {/* AI add-on Questions */}
                    <Row className="mb-3">
                        <Col className="col-md-12">
                            <div className="form-check form-switch mb-3" >
                                <Input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="ai_addon_questions"
                                    name="ai_addon_questions"
                                    // onChange={validation.handleChange}
                                    // onBlur={validation.handleBlur}
                                    checked={validation.values?.ai_addon_questions}
                                    onClick={(e)=> {
                                        const value = validation.values.ai_addon_questions
                                        const name = `ai_addon_questions`
                                        validation.setFieldValue(name, !value);
                                    }}
                                    readOnly
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="ai_addon_questions"
                                >
                                    <h5>
                                        <span id="tooltip-ai-add-on">AI add-on Questions</span>
                                    </h5>
                                   
                                </label>
                            </div>
                        </Col>
                        <Col className="col-mb-12">
                            <Card outline color="secondary" className="border bg-secondary-subtle">
                                {/* <CardHeader className="bg-transparent">
                                
                                </CardHeader> */}
                                <CardBody>
                                    <Row className="row-cols-md-auto g-3 align-items-center">
                                        <Col md={9}>
                                            <div className="align-middle">
                                                <Label className="my-0 text-secondary">
                                                    <i className="mdi mdi-bullseye-arrow me-3" />How Many Questions would you like the AI to suggest for the survey?
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col className="align-middle">
                                            <Select
                                                placeholder="Select"
                                                value={fn_helper.FNFORM.getObjectValue(ddSelect, `${validation.values.ask_number_question}`)}
                                                onChange={(value) => validation.setFieldValue("ask_number_question", value.id)}
                                                getOptionValue={(option) => option.id}
                                                getOptionLabel={(option) => option.name}
                                                options={ddSelect}
                                                className={`select2-selection ${validation.touched.ask_number_question && validation.errors.ask_number_question ? "input-err" : ""}` }
                                                isDisabled={validation.values.ai_addon_questions === false}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col style={{display: "flex", justifyContent: "right"}}>
                            <Button type="submit" color="primary" className="btn btn-primary" 
                                onClick={()=> {
                                    document.getElementById('AI_GEN_QUIZ_BTN').click()
                                }}
                            >Save Question</Button>
                        </Col>
                    </Row>
                </Form>
            </FormikProvider>
        </>
    )
}
export default withTranslation()(QuizChoice)