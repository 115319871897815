// import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Col, Row, Input, Form, FormFeedback, Button, Spinner, Table, Tooltip } from "reactstrap";
import * as Yup from "yup";
import { useFormik, FieldArray, FormikProvider } from "formik";
import Select from "react-select"
import { withTranslation } from "react-i18next";
import '../../components/Common/ts-css.css'
import fn_helper from "helpers/fn_helper";
import Spinners from "components/Common/Spinner";
import { get, post } from "helpers/api_helper";
import useQuestionnaireStore from "../../zustand/store/QuestionnaireModule";

const GenerateQuiz = ({
    pageView = 'create',
    questionnaireUrl = ''
    // questionType = 'multi'
}) => {

    // CENTER STATE
    // const { questionSet = [] } = useQuestionnaireStore(state => state); // STATE
    const { setForm1 } = useQuestionnaireStore(); // ACTION

    // ========== Suggest QUestion ========== //
    const [ btnMode, setBtnMode ] = useState('AI_GEN_QUIZ_X')
    const [ previousQuestion, setPreviousQuestion ] = useState([])
    const [ previousQuestionLoading, setPreviousQuestionLoading ] = useState(false)
    const [ aiQuestion, setAiQuestion ] = useState('')
    const [ aiQuestionLoading, setAiQuestionLoading ] = useState(false)
    // const [ validation, setValidation ] = useState(false)

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const validationSchema = Yup.object({
        topic: Yup.string().max(100),
        purpose: Yup.string().max(512),
        keyword: Yup.string().max(100)
        // topic: Yup.string().max(100).required("Please Enter Topic"),
        // purpose: Yup.string().max(500).required("Please Enter Purpose"),
        // keyword: Yup.string().max(100).required("Please Enter Keyword"),
    });

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            id: pageView == 'create' ? undefined : '',
            topic: '',
            purpose: '',
            keyword: '',
            numberOfQuestion: '1'
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log("🚀 ~ btnMode:", btnMode)
            console.log("🚀 ~ values:", values)
            setForm1(validation)

            const payload = {
                // "question_type": questionType,
                "purpose": values.purpose,
                "topic": values.topic,
                "keyword": values.keyword,
                "number_of_question": values.numberOfQuestion
            }

            console.log("🚀 ~ payload:", payload)

            if (btnMode === 'AI_GEN_QUIZ') {
                if(values.purpose && values.topic && values.keyword) {
                    fetchAiSuggestQuestion(payload)
                }
            }
            else if (btnMode === 'PREV_QUIZ_LIST') {
                // alert('รอก่อน PREV_QUIZ_LIST')
                fetchPreviousQuestion(payload)
            }
        }
    })

    // เรียก AI GEN QUIZ
    const fetchAiSuggestQuestion = async (payload = {}) => {
        setPreviousQuestion([])
        setAiQuestionLoading(true)
        try {
            const result = await post(process.env.REACT_APP_BACKEND_URL + '/v1/admin/questionnaire/aiSuggestQuestion', {
                "question_type": payload.question_type,
                // "category_name": categoryNameData,
                "purpose": payload.purpose,
                "topic": payload.topic,
                "keyword": payload.keyword,
                "number_of_question": payload.number_of_question,
            })
            setAiQuestion(result.data);
            setAiQuestionLoading(false)
        } catch (error) {
            console.log('error', error)
            setAiQuestionLoading(false)
        }
    };

    // เรียก PREV QUIZ
    const fetchPreviousQuestion = async (payload = {}) => {
        setAiQuestion('')
        setPreviousQuestionLoading(true)
        try {
            // const result = await get(process.env.REACT_APP_BACKEND_URL + '/v1/admin/questionnaire/previous/' + categoryNameData + '/' + purposeData + '/' + topicData + '/' + keywordData)
            // const result = await get(`${process.env.REACT_APP_BACKEND_URL}/v1/admin/questionnaire/previous?categoryName=${categoryNameData}&purpose=${purposeData}&topic=${topicData}&keyword=${keywordData}`)
            const result = await get(`${process.env.REACT_APP_BACKEND_URL}/v1/admin/questionnaire/previous?topic=${payload.topic}&keyword=${payload.keyword}`)
            setPreviousQuestion(result.data);
            setPreviousQuestionLoading(false)
        } catch (error) {
            console.log('error', error)
            setPreviousQuestionLoading(false)
        }
    };

    // OTHER FUNCTION
    const handleCopy = (e) => {
        navigator.clipboard.writeText(questionnaireUrl)
            .then(() => {
                setTooltipOpen(true)
                console.log(`${questionnaireUrl} copied to clipboard`);
                setTimeout(() => {
                    setTooltipOpen(false)
                }, 3000);
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    }

    const ddQuizNumber = [...Array(5)].map((item, i) => ({ id: `${i + 1}`, name: `${i + 1}` }))

    

    useEffect(() => {
        console.log("🚀 ~ useEffect ~ validation:", validation)
        setForm1(validation)
    }, [])

    const PageAction = _.startCase(pageView)
    
    return (
        <>
            <h5 className="mb-3">{PageAction} Questionnaire</h5>
            <FormikProvider value={validation}>
                <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    {
                        ['update'].includes(pageView)
                        ? (
                            <Row className="mb-3">
                                <label
                                    htmlFor="questionnaireUrl"
                                    className="col-md-2 col-form-label"
                                >
                                    URL:
                                </label>
                                <Col md={10}>
                                <div className="input-group">
                                    <Input
                                        type="url"
                                        className="form-control-plaintext"
                                        id="questionnaireUrl"
                                        aria-describedby="questionnaireUrlBtn"
                                        aria-label="Questionnaire URL"
                                        value={questionnaireUrl}
                                    />
                                    <button
                                        className="btn btn-secondary"
                                        type="button"
                                        id="questionnaireUrlBtn"
                                        onClick={handleCopy}
                                    >
                                        Copy
                                    </button>
                                    <Tooltip
                                        placement="top"
                                        target="questionnaireUrlBtn"
                                        autohide={true}
                                        isOpen={tooltipOpen}
                                    >
                                        Copied!
                                    </Tooltip>
                                </div>
                                </Col>
                            </Row>
                        )
                        : <></>
                    }


                    <Row className="mb-3">
                        <label
                            htmlFor="topic"
                            className="col-md-2 col-form-label"
                        >
                            Topic:
                        </label>
                        <div className="col-md-10">
                            <Input
                                id="topic"
                                className="form-control"
                                type="text"
                                placeholder="eg: Demographic / Before Trip / During Trip / After Trip"
                                name="topic"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.topic}
                                invalid={
                                    validation.touched.topic && validation.errors.topic ? true : false
                                }
                            />
                            {validation.touched.topic && validation.errors.topic ? (
                                <FormFeedback type="invalid">{validation.errors.topic}</FormFeedback>
                            ) : null}
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <label
                            htmlFor="purpose"
                            className="col-md-2 col-form-label"
                        >
                            Purpose:
                        </label>
                        <div className="col-md-10">
                            <Input
                                id="purpose"
                                name="purpose"
                                className="form-control"
                                type="text"
                                placeholder="eg: The purpose of this interview is to understand the travel experiences of foreign tourists in Japan, to gain a deep understanding of their travel motivations, cultural adaptation and overall satisfaction in Japan"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.purpose}
                                invalid={
                                    validation.touched.purpose && validation.errors.purpose ? true : false
                                }
                            />
                            {validation.touched.purpose && validation.errors.purpose ? (
                                <FormFeedback type="invalid">{validation.errors.purpose}</FormFeedback>
                            ) : null}
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <label
                            htmlFor="keyword"
                            className="col-md-2 col-form-label"
                        >
                            Keyword:
                        </label>
                        <div className="col-md-10">
                            <Input
                                id="keyword"
                                name="keyword"
                                className="form-control"
                                type="text"
                                placeholder="eg: Career / Attractive / Activity / Culture / Accommodation / Food"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.keyword}
                                invalid={
                                    validation.touched.keyword && validation.errors.keyword ? true : false
                                }
                            />
                            {validation.touched.keyword && validation.errors.keyword ? (
                                <FormFeedback type="invalid">{validation.errors.keyword}</FormFeedback>
                            ) : null}
                        </div>
                    </Row>
                    <Row className="mb-4">
                        <Col 
                            md={7} 
                            style={{display: "flex", justifyContent: "left"}}
                        >
                            <div className="col-md-8">
                                <Button
                                    id="AI_GEN_QUIZ_BTN"
                                    color="primary"
                                    type="button"
                                    className="btn btn-primary btn-block"
                                    onClick={(e) => {
                                        setBtnMode('AI_GEN_QUIZ')
                                        validation.handleSubmit();
                                    }}
                                >
                                    {aiQuestionLoading ? <i className="bx bx-loader bx-spin font-size-14 align-middle"></i> : <i className="bx bxs-bolt align-middle"></i>} AI Suggest Question List
                                </Button>
                            </div>
                            <div className="col-md-4">
                                <Select
                                    placeholder="Select"
                                    value={fn_helper.FNFORM.getObjectValue(ddQuizNumber, `${validation.values.numberOfQuestion}`)}
                                    onChange={(value) => validation.setFieldValue("numberOfQuestion", value.id)}
                                    getOptionValue={(option) => option.id}
                                    getOptionLabel={(option) => option.name}
                                    options={ddQuizNumber}
                                    className={`select2-selection ${validation.touched.numberOfQuestion && validation.errors.numberOfQuestion ? "input-err" : ""}` }
                                    isDisabled={pageView === "view"}
                                />
                                {
                                    (validation.touched.numberOfQuestion && validation.errors.numberOfQuestion) && (
                                    <div type="invalid" className="invalid-feedback d-block">{validation.errors.numberOfQuestion}</div>
                                    )
                                }
                            </div>
                        </Col>
                        <Col 
                            md={5} 
                            style={{display: "none", justifyContent: "right"}}
                        >
                            <Button
                                color="primary"
                                type="button"
                                className="btn btn-primary btn-block"
                                onClick={(e) => {
                                    setBtnMode('PREV_QUIZ_LIST')
                                    validation.handleSubmit();
                                }}
                            >
                                Previous Question List {previousQuestionLoading && <Spinner size="sm" className="ms-2" color="light" />}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </FormikProvider>
            <Row className="mb-3">
                <RenderSuggestQuestion
                    loading={aiQuestionLoading}
                    rowData={aiQuestion}
                    setLoading={() => {
                        setAiQuestionLoading(false)
                    }}
                />
            </Row>
            <Row className="mb-3">
                <RenderPreviousQuestion
                    loading={previousQuestionLoading}
                    rowData={previousQuestion}
                    setLoading={() => {
                        setPreviousQuestionLoading(false)
                    }}
                />
            </Row>
        </>
    )
}

export default withTranslation()(GenerateQuiz)

const RenderSuggestQuestion = ({
    loading = false,
    rowData = [],
    setLoading = () => console.log('RenderSuggestQuestion setLoading')
}) => {
    if (rowData.length == 0) {
        return <></>
    }

    if (loading) {
        return <Spinners setLoading={setLoading}/>
    }

    const dataJson = JSON.parse(rowData)
    return(
        <Col style={{whiteSpace: "pre-wrap"}}>
            {
                dataJson.question_text && dataJson.question_text.map((item, i) => {
                    return(
                        <div key={i} className="mb-3">
                            <span className="fw-bold fs-6 text">Question {(i + 1)}: </span>
                            <span className="fs-6 text">{item}</span>
                        </div>
                    )
                })
            }
            {/* <div className="mb-3">
                <span className="fw-bold fs-6 text">Question: </span>
                <span className="fs-6 text">{dataJson.question_text}</span>
            </div>
            <div className="mb-3">
            {
                dataJson.question_choice && dataJson.question_choice.map((item, i) => {
                    return(
                        <div key={i} className="mb-1">
                            <span className="fw-bold fs-6 text">{(i + 1)}: </span>
                            <span className="fs-6 text">{item}</span>
                        </div>
                    )
                })
            }
            </div>
            <div className="mb-3">
            {dataJson.question_choice_answer && <>
                <div>
                    <span className="fw-bold fs-6 text">Answer is: </span>
                    <span className="fs-6 text">{dataJson.question_choice_answer}</span>
                </div>
            </>}
            </div> */}
        </Col>
    )
}

const RenderPreviousQuestion = ({
    loading = false,
    rowData = [],
    setLoading = () => console.log('RenderSuggestQuestion setLoading')
}) => {

    if (rowData.length == 0) {
        return <></>
    }

    if (loading) {
        return <Spinners setLoading={setLoading}/>
    }

    return(
        <>
            <Col
                className="table-responsive"
            >
                <Table className="table-striped-columns">
                    <thead>
                        <tr>
                            {/* <th style={{whiteSpace: 'nowrap'}}>Question Type</th> */}
                            <th style={{whiteSpace: 'nowrap'}}>Question Text</th>
                            {/* <th style={{whiteSpace: 'nowrap'}}>Choice 1</th>
                            <th style={{whiteSpace: 'nowrap'}}>Choice 2</th>
                            <th style={{whiteSpace: 'nowrap'}}>Choice 3</th>
                            <th style={{whiteSpace: 'nowrap'}}>Choice 4</th>
                            <th style={{whiteSpace: 'nowrap'}}>Choice 5</th>
                            <th style={{whiteSpace: 'nowrap'}}>Choice 6</th>
                            <th style={{whiteSpace: 'nowrap'}}>Choice 7</th>
                            <th style={{whiteSpace: 'nowrap'}}>Selected Choice</th>
                            <th style={{whiteSpace: 'nowrap'}}>AI add-on Questions</th>
                            <th style={{whiteSpace: 'nowrap'}}>No. of Add-on</th>
                            <th style={{whiteSpace: 'nowrap'}}>Evaluation Criteria</th>
                            <th style={{whiteSpace: 'nowrap'}}>Relevance Score</th>
                            <th style={{whiteSpace: 'nowrap'}}>Detail Score</th>
                            <th style={{whiteSpace: 'nowrap'}}>Average Score</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rowData.map((item, i) => {
                                return(
                                    <tr key={i}>
                                        {/* <td style={{whiteSpace: 'nowrap'}}>{item.question_type}</td> */}
                                        <td style={{whiteSpace: 'nowrap'}}>{item.question_text}</td>
                                        {/* <td style={{whiteSpace: 'nowrap'}}>{item.choce_text_1}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.choce_text_2}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.choce_text_3}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.choce_text_4}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.choce_text_5}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.choce_text_6}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.choce_text_7}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.choice}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.ai_addon_questions && 'Checked'}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.ask_number_question}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.evaluation_criteria && 'Checked'}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.relevance_score}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.detail_score}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.average_score}</td> */}
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </Col>
        </>
    )
}