import React from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  Input,
  Form,
  FormFeedback,
  Button,
} from "reactstrap";
import { useFormik, FieldArray, FormikProvider } from "formik";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";

const TestForm = (props) => {
  // Validation Schema
  const validationSchema = Yup.object().shape({
    banners: Yup.array().of(
      Yup.object().shape({
        bannerName: Yup.string().max(100).required("Please Enter Banner Name"),
      })
    ),
  });

  // Initial Values
  const initialValues = {
    banners: [
      {
        bannerName: "",
      },
      {
        bannerName: "",
      },
      {
        bannerName: "",
      },
    ],
  };

  // useFormik hook
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log("🚀 ~ Submitted Values:", values);
    },
  });

  // Custom handleChange function
  const customHandleChange = (e, index) => {
    const { name, value } = e.target;

    // Example: Perform custom logic, e.g., transforming value
    const transformedValue = value.toUpperCase(); // Example transformation

    // Update Formik's field value with transformed value
    console.log("🚀 ~ customHandleChange ~ name:", name);
    console.log("🚀 ~ customHandleChange ~ transformedValue:", transformedValue);
    formik.setFieldValue(name, transformedValue);

    // Call Formik's handleChange
    formik.handleChange(e);
  };

  return (
    <FormikProvider value={formik}>
      <Form
        className="form-horizontal"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <Card>
          <CardBody>
            <Row className="mb-3">
              <h5 className="mb-3">Banner List Information</h5>
              <FieldArray name="banners">
                {({ push, remove }) => (
                  <>
                    {formik.values.banners.map((banner, index) => (
                      <Row key={index} className="mb-3">
                        <Col md={10}>
                          <label
                            htmlFor={`banners.${index}.bannerName`}
                            className="col-form-label"
                          >
                            Banner Name
                          </label>
                          <Input
                            id={`banners.${index}.bannerName`}
                            name={`banners.${index}.bannerName`}
                            className="form-control"
                            type="text"
                            placeholder="Enter Banner Name"
                            onChange={(e) => customHandleChange(e, index)}
                            onBlur={formik.handleBlur}
                            value={banner.bannerName}
                            invalid={!!(formik.touched.banners?.[index]?.bannerName && formik.errors.banners?.[index]?.bannerName)}
                          />
                          {!!(formik.touched.banners?.[index]?.bannerName && formik.errors.banners?.[index]?.bannerName)? (
                            <FormFeedback type="invalid">
                              {formik.errors.banners[index].bannerName}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Button
                            color="danger"
                            type="button"
                            onClick={() => remove(index)} // Correctly removes the banner at this index
                          >
                            Remove
                          </Button>
                        </Col>
                      </Row>
                    ))}
                    <Button
                      color="primary"
                      type="button"
                      onClick={() =>
                        push({
                          bannerName: "", // Add a new empty banner
                        })
                      }
                    >
                      Add Banner
                    </Button>
                  </>
                )}
              </FieldArray>
            </Row>
            <Row>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  gap: "8px",
                  marginTop: "16px",
                }}
              >
                <Button type="submit" color="primary">
                  SAVE
                </Button>
              </div>
            </Row>
          </CardBody>
        </Card>
      </Form>
    </FormikProvider>
  );
};

export default withTranslation()(TestForm);
