import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//Dashboard 
import Dashboard from "./dashboard/reducer";

//Questionaire
import QuestionaireCategoryCreate from "./questionaire/category/create/reducer"
import questionaireCategoryEdit from "./questionaire/category/update/reducer"
import QuestionaireQuestionCreate from "./questionaire/question/create/reducer"
import QuestionaireQuestionUpdate from "./questionaire/question/update/reducer"
import questionaireConsent from "./questionaire/consent/update/reducer";


const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Dashboard,
  QuestionaireCategoryCreate,
  QuestionaireQuestionCreate,
  QuestionaireQuestionUpdate,
  questionaireCategoryEdit,
  questionaireConsent,
});

export default rootReducer;
