// import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Col, Row, Input, Form, FormFeedback, Button, Spinner, Table } from "reactstrap";
import { Label, InputGroup, Card, CardBody, CardHeader } from "reactstrap";
import * as Yup from "yup";
import { useFormik, FieldArray, FormikProvider } from "formik";
import Select from "react-select"
import { withTranslation } from "react-i18next";
import '../../components/Common/ts-css.css'
import fn_helper from "helpers/fn_helper";
import { ReactSortable } from "react-sortablejs";
import useQuestionnaireStore from "../../zustand/store/QuestionnaireModule";
import DisplayCriteria from "./DisplayCriteria";
import DisplayChoice from "./DisplayChoice";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const QuizTable = ({
    pageView = 'create',
    onSubmit = () => console.log('onSubmit!!')
}) => {

    let navigate = useNavigate();
    const { categoryId } = useParams();

    // CENTER STATE
    const { questionSet = [] } = useQuestionnaireStore(state => state); // STATE
    const { setQuestionSet } = useQuestionnaireStore(); // ACTION

    const [allQuestionAddOns, setAllQuestionAddOns] = useState(false);

    const onMoveCard = (newState)=> {
        // console.log("🚀 ~ XXX oldState:", questionSet)
        // FIND Index Move
        // let indexMove = -1
        let indexMove = []
        for (let i in newState) {
            const oldRow = questionSet[i]
            const newRow = newState[i]
            // console.log("🚀 XXX setList ~ oldRow:", oldRow)
            // console.log("🚀 XXX setList ~ newRow:", newRow)
            if (_.isEqual(oldRow, newRow) === false) {
                console.log('🚀 ~ XXX return I', i)
                indexMove.push(`${i}`)
                // break
            }
        }

        console.log("🚀 ~ indexMove:", indexMove) // การย้ายครั้งนี้กระทบอะไรบ้าง
        if (indexMove.length) {
            newState = newState.map((item, i) => {
                if (indexMove.includes(`${i}`)) {
                    item.goto = 'Next Question'
                }
                return item
            })
        }
        console.log("🚀 ~ XXX return newState:", newState)
        setQuestionSet(newState)

    }

    return(
        <>
            <Row>
                <Col md={6}>
                    <h5 className="mb-3">Your Question Set</h5>
                </Col>
                <Col md={6}>
                    <div className="form-check form-switch mb-3" style={{ display: "flex", justifyContent: "right" }}>
                        <Row>
                            <Col>
                                <input 
                                    type="checkbox" 
                                    className="form-check-input" 
                                    id="toggleAllAddOns"
                                    checked={allQuestionAddOns}
                                    onClick={()=> {
                                        console.log('allQuestionAddOns >>', allQuestionAddOns)
                                        const valueUpdate = !allQuestionAddOns
                                        setAllQuestionAddOns(valueUpdate)

                                        // update toggle
                                        let tmpUpdate = _.cloneDeep(questionSet) 
                                        tmpUpdate = tmpUpdate.map(item => {
                                            item.ai_addon_questions = valueUpdate
                                            return item
                                        })
                                        setQuestionSet(tmpUpdate)
                                    }}
                                />
                                <label className="form-check-label" htmlFor="toggleAllAddOns">Make All Question to Add Ons</label>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            {/* questionSet :
            {
                JSON.stringify(questionSet)
            } */}
            <div id="questionListZone">
                <ReactSortable 
                    list={questionSet} 
                    setList={(newState)=> {
                        onMoveCard(newState)
                        // console.log("🚀 ~ XXX oldState:", questionSet)
                        // FIND Index Move
                        // let indexMove = -1
                    }} 
                    swap={true} 
                    animation={150} 
                    handle=".handle"
                >
                    {/* {renderSortable(questionSet)} */}
                    
                    <RenderSortable 
                        rowData={questionSet} 
                        onMove={(action, index) => {
                            const newState = _.cloneDeep(questionSet)
                            // สลับ INDEX
                            let tmpRow = {}
                            let indexMove = index
                            if (action === 'UP') {
                                indexMove = index - 1
                            } else if (action === 'DOWN') {
                                indexMove = index + 1
                            }
                            tmpRow = { ...questionSet[indexMove] }

                            newState[indexMove] = { ...questionSet[index]}
                            newState[index] = tmpRow

                            onMoveCard(newState)
                        }}
                    />
                </ReactSortable>
                <Row className="mb-3">
                    <Col>
                        {/* <div className="form-check mb-3" style={{display: "flex", justifyContent: "center"}}> */}
                            {/* <button className="btn btn-outline-primary">Preview Questionaire</button>&nbsp; */}
                            <Row>
                                <Col md={12}>
                                    <div className="form-check mb-3" style={{display: "flex", justifyContent: "center"}}>
                                        <button
                                            className="btn btn-danger"
                                            onClick={() => {
                                                if (["update"].includes(pageView)) {
                                                    navigate(`/questionnaire/category/edit/${categoryId}`)
                                                } else {
                                                    navigate(`/questionnaire/category/create`)
                                                }
                                            }}
                                        >
                                            Back
                                        </button>
                                        &nbsp;
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => {
                                                const payloadSave = _.cloneDeep(questionSet) 
                                                onSubmit(payloadSave)
                                            }}
                                        >
                                            Save Questionnaire 
                                            {/* { true || sendQuestion && <Spinner size="sm" className="ms-2" color="light" />} */}
                                        </button>
                                    </div>
                                </Col>
                                {/* <Col md={6}>
                                    <div className="form-check mb-3" style={{display: "flex", justifyContent: "center"}}>
                                        <button
                                            className="btn btn-primary"
                                            onClick={handleSaveQuestionaire}
                                        >
                                            Save Questionnaire {sendQuestion && <Spinner size="sm" className="ms-2" color="light" />}
                                        </button>
                                    </div>
                                </Col> */}
                            </Row>
                        {/* </div> */}
                    </Col>
                </Row>
            </div>
        </>
    )
}
export default withTranslation()(QuizTable)


const RenderSortable = ({ 
    rowData = [],
    onMove = (action, index) => console.log('onMove')
}) => {
    const data = rowData
    return data.map((item, i) => {
        switch (item.question_type) {
            case "multi":
            case "single":
                return (
                    <div>
                        <DisplayChoice
                            key={i}
                            idx={i}
                            rowData={item}
                            rowsLength={data.length}
                            onMove={onMove}
                        />
                    </div>
                    
                )
                break;
            case "openend":
            case "image":
                return (
                    <div>
                        <DisplayCriteria
                            key={i}
                            idx={i}
                            rowData={item}
                            rowsLength={data.length}
                            onMove={onMove}
                        />
                    </div>
                )
        }
    })
}