import React, { useEffect, useState } from "react"

import {
    Col,
    Row,
    Card,
    CardBody,
    // CardTitle,
    // CardSubtitle,
    // CardImg,
    // CardText,
    // CardHeader,
    // CardImgOverlay,
    // CardFooter,
    // CardDeck,
    Container,
    Label,
    Input,
    InputGroup,
    Form,
    FormFeedback,
    Nav, NavItem, NavLink, TabContent, TabPane,
    Button,
    Spinner
} from "reactstrap";

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from '@tinymce/tinymce-react';

import classnames from "classnames";

import { get, post } from "helpers/api_helper";
import { checkScope } from "helpers/utility_helper";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { apiError } from "../../store/actions";
import { updateQuestionaireCategory, updateQuestionaireCategoryReset } from "../../store/actions"

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb2';
import { withTranslation } from "react-i18next";
import '../../components/Common/ts-css.css'
import { Link, useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";

const QuestionaireCategoryEdit = props => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [, rootMenu, subMenu, action] = location.pathname.split('/')
    const { categoryId } = useParams();

    document.title = "Questionnaire | Create Category | " + process.env.REACT_APP_CMS_TITLE

    // ========== Get Question from Server ========== //
    // const [oldCategoryId, setOldCategoryId] = useState();
    // setOldCategoryId(categoryId)
    // const [createByData, setCreateByData] = useState();
    // const [createDateData, setCreateDateData] = useState();
    const [ sendQuestion, setSendQuestion ] = useState(false)
    const [contentDetail, setContentDetail] = useState({
        id: '',
        category_name: '',
        survey_name: '',
        purpose: '',
        // age_range_start: '',
        // age_range_end: '',
        // gender: '',
        // nationality: '',
        // martial_status: '',

        criteria_relevance_text: '',
        criteria_relevance_context: '',
        criteria_relevance_good: '',
        criteria_relevance_poor: '',
        criteria_relevance_bad: '',

        criteria_clarity_text: '',
        criteria_clarity_context: '',
        criteria_clarity_good: '',
        criteria_clarity_poor: '',
        criteria_clarity_bad: '',

        criteria_engagement_text: '',
        criteria_engagement_context: '',
        criteria_engagement_good: '',
        criteria_engagement_poor: '',
        criteria_engagement_bad: '',

        criteria_response_usefulness_text: '',
        criteria_response_usefulness_context: '',
        criteria_response_usefulness_good: '',
        criteria_response_usefulness_poor: '',
        criteria_response_usefulness_bad: '',

        criteria_information_gain_text: '',
        criteria_information_gain_context: '',
        criteria_information_gain_good: '',
        criteria_information_gain_poor: '',
        criteria_information_gain_bad: '',
        
        rules: '',
        remarks: '',
        create_by: '',
        create_date: '',
    });
    const [contentDetailLoading, setContentDetailLoading] = useState(true);

    useEffect(() => {
        const fetchContent = async () => {
            const result = await get(process.env.REACT_APP_BACKEND_URL + '/v1/admin/questionnaire/category/' + categoryId)
            const _content = {
                id: result.data.id !== null ? result.data.id : '',
                category_name: result.data.category_name !== null ? result.data.category_name : '',
                survey_name: result.data.survey_name !== null ? result.data.survey_name : '',
                purpose: result.data.purpose !== null ? result.data.purpose : '',
                age_range_start: result.data.age_range_start !== null ? result.data.age_range_start : '',
                age_range_end: result.data.age_range_end !== null ? result.data.age_range_end : '',
                gender: result.data.gender !== null ? result.data.gender : '',
                nationality: result.data.nationality !== null ? result.data.nationality : '',
                martial_status: result.data.martial_status !== null ? result.data.martial_status : '',

                criteria_relevance_checkbox: result.data.criteria_relevance_checkbox !== null ? result.data.criteria_relevance_checkbox : '',
                criteria_relevance_text: result.data.criteria_relevance_text !== null ? result.data.criteria_relevance_text : '',
                criteria_relevance_context: result.data.criteria_relevance_context !== null ? result.data.criteria_relevance_context : '',
                criteria_relevance_good: result.data.criteria_relevance_good !== null ? result.data.criteria_relevance_good : '',
                criteria_relevance_poor: result.data.criteria_relevance_poor !== null ? result.data.criteria_relevance_poor : '',
                criteria_relevance_bad: result.data.criteria_relevance_bad !== null ? result.data.criteria_relevance_bad : '',

                criteria_clarity_checkbox: result.data.criteria_clarity_checkbox !== null ? result.data.criteria_clarity_checkbox : '',
                criteria_clarity_text: result.data.criteria_clarity_text !== null ? result.data.criteria_clarity_text : '',
                criteria_clarity_context: result.data.criteria_clarity_context !== null ? result.data.criteria_clarity_context : '',
                criteria_clarity_good: result.data.criteria_clarity_good !== null ? result.data.criteria_clarity_good : '',
                criteria_clarity_poor: result.data.criteria_clarity_poor !== null ? result.data.criteria_clarity_poor : '',
                criteria_clarity_bad: result.data.criteria_clarity_bad !== null ? result.data.criteria_clarity_bad : '',

                criteria_engagement_checkbox: result.data.criteria_engagement_checkbox !== null ? result.data.criteria_engagement_checkbox : '',
                criteria_engagement_text: result.data.criteria_engagement_text !== null ? result.data.criteria_engagement_text : '',
                criteria_engagement_context: result.data.criteria_engagement_context !== null ? result.data.criteria_engagement_context : '',
                criteria_engagement_good: result.data.criteria_engagement_good !== null ? result.data.criteria_engagement_good : '',
                criteria_engagement_poor: result.data.criteria_engagement_poor !== null ? result.data.criteria_engagement_poor : '',
                criteria_engagement_bad: result.data.criteria_engagement_bad !== null ? result.data.criteria_engagement_bad : '',

                criteria_response_usefulness_checkbox: result.data.criteria_response_usefulness_checkbox !== null ? result.data.criteria_response_usefulness_checkbox : '',
                criteria_response_usefulness_text: result.data.criteria_response_usefulness_text !== null ? result.data.criteria_response_usefulness_text : '',
                criteria_response_usefulness_context: result.data.criteria_response_usefulness_context !== null ? result.data.criteria_response_usefulness_context : '',
                criteria_response_usefulness_good: result.data.criteria_response_usefulness_good !== null ? result.data.criteria_response_usefulness_good : '',
                criteria_response_usefulness_poor: result.data.criteria_response_usefulness_poor !== null ? result.data.criteria_response_usefulness_poor : '',
                criteria_response_usefulness_bad: result.data.criteria_response_usefulness_bad !== null ? result.data.criteria_response_usefulness_bad : '',

                criteria_information_gain_checkbox: result.data.criteria_information_gain_checkbox !== null ? result.data.criteria_information_gain_checkbox : '',
                criteria_information_gain_text: result.data.criteria_information_gain_text !== null ? result.data.criteria_information_gain_text : '',
                criteria_information_gain_context: result.data.criteria_information_gain_context !== null ? result.data.criteria_information_gain_context : '',
                criteria_information_gain_good: result.data.criteria_information_gain_good !== null ? result.data.criteria_information_gain_good : '',
                criteria_information_gain_poor: result.data.criteria_information_gain_poor !== null ? result.data.criteria_information_gain_poor : '',
                criteria_information_gain_bad: result.data.criteria_information_gain_bad !== null ? result.data.criteria_information_gain_bad : '',

                rules: result.data.rules !== null ? result.data.rules : '',
                remarks: result.data.remarks !== null ? result.data.remarks : '',

                create_by: result.data.create_by !== null ? result.data.create_by : '',
                create_date: result.data.create_date !== null ? result.data.create_date : '',
            }
            
            document.getElementById('criteria_relevance_checkbox').checked = typeof result.data.criteria_relevance_checkbox !== 'undefined'
            document.getElementById('criteria_clarity_checkbox').checked = typeof result.data.criteria_clarity_checkbox !== 'undefined'
            document.getElementById('criteria_engagement_checkbox').checked = typeof result.data.criteria_engagement_checkbox !== 'undefined'
            document.getElementById('criteria_response_usefulness_checkbox').checked = typeof result.data.criteria_response_usefulness_checkbox !== 'undefined'
            document.getElementById('criteria_information_gain_checkbox').checked = typeof result.data.criteria_information_gain_checkbox !== 'undefined'

            setRelevanceInput(!isEmpty(result.data.criteria_relevance_checkbox))
            setClarityInput(!isEmpty(result.data.criteria_clarity_checkbox))
            setEngagementInput(!isEmpty(result.data.criteria_engagement_checkbox))
            setRespusefInput(!isEmpty(result.data.criteria_response_usefulness_checkbox))
            setInfogainInput(!isEmpty(result.data.criteria_information_gain_checkbox))

            setGenderSelected({label: result.data.gender, value: result.data.gender})
            setNationalitySelected({label: result.data.nationality, value: result.data.nationality})
            setMartialStatusSelected({label: result.data.martial_status, value: result.data.martial_status})
            
            console.log(_content)

            setContentDetail(_content);
            setContentDetailLoading(false)
        };
    
        fetchContent();
    }, []);

    useEffect(() => {
        setContentDetailLoading(false)
    }, [contentDetail])
    // ========== Get User from Server ========== //

    const [verticalActiveTab, setverticalActiveTab] = useState("1");

    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab);
        }
    };

    const [genderInvalid, setGenderInvalid] = useState(true)
    const genderInvalidClass = classnames({
        'select-basic-single': true,
        'is-invalid': genderInvalid
    })

    const [nationalityInvalid, setNationalityInvalid] = useState(true)
    const nationalityInvalidClass = classnames({
        'select-basic-single': true,
        'is-invalid': nationalityInvalid
    })

    const [martialInvalid, setMartialInvalid] = useState(true)
    const martialInvalidClass = classnames({
        'select-basic-single': true,
        'is-invalid': martialInvalid
    })

    const [genderDropdown, setGenderDropdown] = useState([]);
    const [genderSelected, setGenderSelected] = useState();

    const [nationalityDropdown, setNationalityDropdown] = useState([]);
    const [nationalitySelected, setNationalitySelected] = useState();

    const [martialStatusDropdown, setMartialStatusDropdown] = useState([]);
    const [martialStatusSelected, setMartialStatusSelected] = useState();

    const buildDropdown = () => {

        // ========== Gender ========== //
        const _gender = [
            {
                label: 'Any',
                value: 'Any'
            },
            {
                label: 'Male',
                value: 'Male'
            },
            {
                label: 'Female',
                value: 'Female'
            },
            
        ]

        setGenderDropdown(_gender)
        // ========== Gender ========== //
        // ========== Nationality ========== //
        const _nationality = [
            {
                label: 'Any',
                value: 'Any'
            },
            {
                label: 'Thai',
                value: 'Thai'
            }
            
        ]

        setNationalityDropdown(_nationality)
        // ========== Nationality ========== //
        // ========== Martial Status ========== //
        const _matialStatus = [
            {
                label: 'Any',
                value: 'Any'
            },
            {
                label: 'Single',
                value: 'Single'
            },
            {
                label: 'Married',
                value: 'Married'
            },
        ]

        setMartialStatusDropdown(_matialStatus)
        // ========== Martial Status ========== //
    }

    useEffect(() => {
        buildDropdown()
    }, [])

    // ========== Form Validation ========== //
    const validationSchema = Yup.object({
        category_name: Yup.string().max(60)
            .required("Please Enter Category Name"),
        survey_name: Yup.string().max(60)
            .required("Please Enter Survey Name"),
        purpose: Yup.string()
            .required("Please Enter Purpose"),
        // age_range_start: Yup.number()
        //     .integer()
        //     .positive()
        //     .required("Please Enter Age range start")
        //     .min(1)
        //     .max(99),
        // age_range_end: Yup.number()
        //     .integer()
        //     .positive()
        //     .moreThan(Yup.ref("age_range_start"), "Please enter Age range end more than Age range start")
        //     .required("Please Enter  Age range end")
        //     .min(1)
        //     .max(99),
        // gender: Yup.string()
        //     .required("Please Enter Gender"),
        // nationality: Yup.string()
        //     .required("Please Enter Nationality"),
        // martial_status: Yup.string()
        //     .required("Please Enter Martial Status"),
        criteria_relevance_text: Yup.string()
            .required("Fill Text"),
        criteria_relevance_context: Yup.string()
            .required("Fill Text"),
        criteria_relevance_good: Yup
            .string()
            .required("Fill Text"),
        criteria_relevance_poor: Yup
            .string()
            .required("Fill Text"),
        criteria_relevance_bad: Yup
            .string()
            .required("Fill Text"),
        criteria_clarity_text: Yup.string()
            .required("Fill Text"),
        criteria_clarity_context: Yup.string()
            .required("Fill Text"),
        criteria_clarity_good: Yup
            .string()
            .required("Fill Text"),
        criteria_clarity_poor: Yup
            .string()
            .required("Fill Text"),
        criteria_clarity_bad: Yup
            .string()
            .required("Fill Text"),
        criteria_engagement_text: Yup
            .string()
            .required("Fill Text"),
        criteria_engagement_context: Yup
            .string()
            .required("Fill Text"),
        criteria_engagement_good: Yup
            .string()
            .required("Fill Text"),
        criteria_engagement_poor: Yup
            .string()
            .required("Fill Text"),
        criteria_engagement_bad: Yup
            .string()
            .required("Fill Text"),
        criteria_response_usefulness_text: Yup
            .string()
            .required("Fill Text"),
        criteria_response_usefulness_context: Yup
            .string()
            .required("Fill Text"),
        criteria_response_usefulness_good: Yup
            .string()
            .required("Fill Text"),
        criteria_response_usefulness_poor: Yup
            .string()
            .required("Fill Text"),
        criteria_response_usefulness_bad: Yup
            .string()
            .required("Fill Text"),
        criteria_information_gain_text: Yup.string()
            .required("Fill Text"),
        criteria_information_gain_context: Yup.string()
            .required("Fill Text"),
        criteria_information_gain_good: Yup
            .string()
            .required("Fill Text"),
        criteria_information_gain_poor: Yup
            .string()
            .required("Fill Text"),
        criteria_information_gain_bad: Yup
            .string()
            .required("Fill Text"),
        remarks: Yup.string()
            .required("Please Enter Remarks"),
        rules: Yup.string()
            .required("Please Enter Rules"),
    });

    const [updatedValidationSchema, setUpdatedValidationSchema] = useState(
        validationSchema
    );

    const [ oldCategoryData, setoldCategoryData ] = useState(localStorage.getItem("categoryData"))
    useEffect(() => {
        if (oldCategoryData !== null) {
            const _ol = JSON.parse(oldCategoryData)
            setContentDetail(_ol)
        }
    }, [oldCategoryData])

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: contentDetail,
        // {
        //     category_name: '',
        //     purpose: '',
        //     age_range_start: '',
        //     age_range_end: '',
        //     gender: '',
        //     nationality: '',
        //     martial_status: '',

        //     // criteria_checkbox: [],
        //     criteria_relevance_checkbox: 'Relevance',
        //     criteria_relevance_text: "whether the generated question is related to the respondent's answer and the interview topic.",
        //     criteria_relevance_context: 'I really enjoyed experiencing the traditional festivals in Japan.',
        //     criteria_relevance_good: 'Which traditional festivals in Japan did you attend, and what were the highlights for you during these festivals?',
        //     criteria_relevance_poor: 'What kind of Japanese food did you try?',
        //     criteria_relevance_bad: 'What is your favorite movie genre?',

        //     criteria_clarity_checkbox: 'Clarity',
        //     criteria_clarity_text: 'Whether the generated question is clearly expressed and easy to understand.',
        //     criteria_clarity_context: 'I loved visiting the historic sites in Kyoto.',
        //     criteria_clarity_good: 'What specific historic sites in Kyoto did you visit, and what did you find most interesting about them?',
        //     criteria_clarity_poor: 'Which historic sites in Kyoto did you visit?',
        //     criteria_clarity_bad: 'What about Kyoto?',

        //     criteria_engagement_checkbox: 'Engagement Potential',
        //     criteria_engagement_text: 'Whether the generated question encourages the respondent to provide detailed, enthusiastic, or engaging responses.',
        //     criteria_engagement_context: 'I was amazed by the architecture of the temples in Kyoto.',
        //     criteria_engagement_good: 'Which temples in Kyoto did you find the most impressive, and what specific architectural features or historical aspects captured your interest?',
        //     criteria_engagement_poor: 'What did you like about the temples in Kyoto?',
        //     criteria_engagement_bad: 'Did you see any buildings?',

        //     criteria_response_usefulness_checkbox: 'Response Usefulness',
        //     criteria_response_usefulness_text: "The practical usefulness of the respondent's answers generated by the question for further analysis or understanding the interview subject.",
        //     criteria_response_usefulness_context: 'I found the public transportation system in Japan very efficient.',
        //     criteria_response_usefulness_good: 'Can you describe any specific aspects of the public transportation system in Japan that you found particularly efficient or challenging, and how did it compare to the system in your home country?',
        //     criteria_response_usefulness_poor: 'Did you find it easy to use?',
        //     criteria_response_usefulness_bad: 'Did you take the bus?',

        //     criteria_information_gain_checkbox: 'Information Gain',
        //     criteria_information_gain_text: 'Whether the generated question leads the respondent to provide new, in-depth information.',
        //     criteria_information_gain_context: 'I enjoyed exploring the various districts of Tokyo, especially Shibuya and Shinjuku.',
        //     criteria_information_gain_good: 'What specific activities or experiences did you enjoy most in Shibuya and Shinjuku, and how did they differ?',
        //     criteria_information_gain_poor: 'Did you find Shibuya or Shinjuku more interesting?',
        //     criteria_information_gain_bad: 'Did you visit Tokyo?',

        //     remarks: "* For the Engagement Potential index, it's important not to ask overly specialized questions that the respondent may not be able to answer.\n* In the Information Gain index, it's important to ensure that the questions do not repeat previously asked questions or any predetermined fixed questions.\n* AI needs to follow the predetermined rules.",
        //     rules: "* When user first got the low score in 1 question, please rephrase the question, and ask the same meaning to users.\n* If they still got the low score for the same question, please use the highest score and passed this question.\n* When user got 2 low scores in different questions in all 5 questions, please remind users to make more details or give more related answers depending on their score in relevance or details.\n* If users got 3 low scores in different questions in all 5 questions. Then they fail the survey, and can’t pass the test.\n* For each question, please give users 2 chances to make high score."
        // },
        validationSchema: updatedValidationSchema,
        onSubmit: (values) => {
            localStorage.setItem('categoryData', JSON.stringify(values))
            navigate(`/questionnaire/edit/` + categoryId)
        }
    })

    const [relevanceInput, setRelevanceInput] = useState()
    const [clarityInput, setClarityInput] = useState()
    const [engagementInput, setEngagementInput] = useState()
    const [respusefInput, setRespusefInput] = useState()
    const [infogainInput, setInfogainInput] = useState()

    const handleCheckedChange = (e) => {
        if(e.target.checked == true){
            switch (e.target.name) {
                case "criteria_relevance_checkbox":
                    setRelevanceInput(true)
                    break;
                case "criteria_clarity_checkbox":
                    setClarityInput(true)
                    break;
                case "criteria_engagement_checkbox":
                    setEngagementInput(true)
                    break;
                case "criteria_response_usefulness_checkbox":
                    setRespusefInput(true)
                    break;
                case "criteria_information_gain_checkbox":
                    setInfogainInput(true)
                    break;
            }

            validation.setFieldValue(e.target.name, e.target.value)
        }
        else {
            switch (e.target.name) {
                case "criteria_relevance_checkbox":
                    setRelevanceInput(false)
                    break;
                case "criteria_clarity_checkbox":
                    setClarityInput(false)
                    break;
                case "criteria_engagement_checkbox":
                    setEngagementInput(false)
                    break;
                case "criteria_response_usefulness_checkbox":
                    setRespusefInput(false)
                    break;
                case "criteria_information_gain_checkbox":
                    setInfogainInput(false)
                    break;
            }

            validation.setFieldValue(e.target.name, '')
        }
    };

    useEffect(() => {
        if(relevanceInput) {
            let newSchema = updatedValidationSchema.concat(
                Yup.object().shape({
                    criteria_relevance_text: Yup.string().required("Fill Text"),
                    criteria_relevance_context: Yup.string().required("Fill Text"),
                    criteria_relevance_good: Yup.string().required("Fill Text"),
                    criteria_relevance_poor: Yup.string().required("Fill Text"),
                    criteria_relevance_bad: Yup.string().required("Fill Text"),
                })
            );

            setUpdatedValidationSchema(newSchema);
        }
        else {
            let newSchema = updatedValidationSchema.omit([
                'criteria_relevance_text',
                'criteria_relevance_context',
                'criteria_relevance_good',
                'criteria_relevance_poor',
                'criteria_relevance_bad',
            ])

            setUpdatedValidationSchema(newSchema)
        }
    }, [relevanceInput])

    useEffect(() => {
        if(clarityInput) {
            let newSchema = updatedValidationSchema.concat(
                Yup.object().shape({
                    criteria_clarity_text: Yup.string().required("Fill Text"),
                    criteria_clarity_context: Yup.string().required("Fill Text"),
                    criteria_clarity_good: Yup.string().required("Fill Text"),
                    criteria_clarity_poor: Yup.string().required("Fill Text"),
                    criteria_clarity_bad: Yup.string().required("Fill Text"),
                })
            )

            setUpdatedValidationSchema(newSchema);
        }
        else {
            let newSchema = updatedValidationSchema.omit([
                'criteria_clarity_text',
                'criteria_clarity_context',
                'criteria_clarity_good',
                'criteria_clarity_poor',
                'criteria_clarity_bad',
            ])

            setUpdatedValidationSchema(newSchema)
        }
    }, [clarityInput])

    useEffect(() => {
        if(engagementInput) {
            let newSchema = updatedValidationSchema.concat(
                Yup.object().shape({
                    criteria_engagement_text: Yup.string().required("Fill Text"),
                    criteria_engagement_context: Yup.string().required("Fill Text"),
                    criteria_engagement_good: Yup.string().required("Fill Text"),
                    criteria_engagement_poor: Yup.string().required("Fill Text"),
                    criteria_engagement_bad: Yup.string().required("Fill Text"),
                })
            )

            setUpdatedValidationSchema(newSchema);
        }
        else {
            let newSchema = updatedValidationSchema.omit([
                'criteria_engagement_text',
                'criteria_engagement_context',
                'criteria_engagement_good',
                'criteria_engagement_poor',
                'criteria_engagement_bad',
            ])

            setUpdatedValidationSchema(newSchema)
        }
    }, [engagementInput])

    useEffect(() => {
        if(respusefInput) {
            let newSchema = updatedValidationSchema.concat(
                Yup.object().shape({
                    criteria_response_usefulness_text: Yup.string().required("Fill Text"),
                    criteria_response_usefulness_context: Yup.string().required("Fill Text"),
                    criteria_response_usefulness_good: Yup.string().required("Fill Text"),
                    criteria_response_usefulness_poor: Yup.string().required("Fill Text"),
                    criteria_response_usefulness_bad: Yup.string().required("Fill Text"),
                })
            )

            setUpdatedValidationSchema(newSchema);
        }
        else {
            let newSchema = updatedValidationSchema.omit([
                'criteria_response_usefulness_text',
                'criteria_response_usefulness_context',
                'criteria_response_usefulness_good',
                'criteria_response_usefulness_poor',
                'criteria_response_usefulness_bad',
            ])

            setUpdatedValidationSchema(newSchema)
        }
    }, [respusefInput])

    useEffect(() => {
        if(infogainInput) {
            let newSchema = updatedValidationSchema.concat(
                Yup.object().shape({
                    criteria_information_gain_text: Yup.string().required("Fill Text"),
                    criteria_information_gain_context: Yup.string().required("Fill Text"),
                    criteria_information_gain_good: Yup.string().required("Fill Text"),
                    criteria_information_gain_poor: Yup.string().required("Fill Text"),
                    criteria_information_gain_bad: Yup.string().required("Fill Text"),
                })
            )

            setUpdatedValidationSchema(newSchema);
        }
        else {
            let newSchema = updatedValidationSchema.omit([
                'criteria_information_gain_text',
                'criteria_information_gain_context',
                'criteria_information_gain_good',
                'criteria_information_gain_poor',
                'criteria_information_gain_bad',
            ])

            setUpdatedValidationSchema(newSchema)
        }
    }, [infogainInput])
    // ========== Form Validation ========== //
    
    const QuestionaireCategoryProperties = createSelector(
        (state) => state.questionaireCategoryEdit,
        (questionaireCategory) => ({
            categoryData: questionaireCategory.categoryData,
            updateError: questionaireCategory.updateError,
            updateSuccess: questionaireCategory.updateSuccess,
            loading: questionaireCategory.loading,
        })
    );

    const {
        categoryData, updateError, updateSuccess, loading
    } = useSelector(QuestionaireCategoryProperties);
    
    useEffect(() => {
        dispatch(apiError(""));
    }, []);

    useEffect(() => {
        console.log('categoryData', categoryData)
        updateSuccess && setTimeout(() => {
            dispatch(updateQuestionaireCategoryReset())
            navigate(`/questionnaire/edit/${categoryId}/${categoryData.id}`)
        }, 2000)
    }, [updateSuccess, categoryData])

    if (contentDetailLoading) {
        return <Spinners setLoading={setContentDetailLoading} />
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={false}>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs 
                        title={props.t("Questionnaire")} 
                        breadcrumbItems={[
                            {title: "Questionnaire", link: "questionnaire"}, 
                            {title: "Create Category", link: "category"},
                        ]}
                    />
                    <Row>
                        <Col>
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Card>
                                    <CardBody>
                                        <Row className="mb-3">
                                            <h5 className="mb-3">Create Questionaire Category</h5>
                                            <Col className="col-12">
                                                <label
                                                    htmlFor="category_name"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Category
                                                </label>
                                                <div className="col-md-4">
                                                    <Input
                                                        id="category_name"
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="eg. Travel in Japan / Skincare / Car"
                                                        name="category_name"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.category_name}
                                                        invalid={
                                                            validation.touched.category_name && validation.errors.category_name ? true : false
                                                        }
                                                    />
                                                    {validation.touched.category_name && validation.errors.category_name ? (
                                                        <FormFeedback type="invalid">{validation.errors.category_name}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col className="col-12">
                                                <label
                                                    htmlFor="survey_name"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Name
                                                </label>
                                                <div className="col-md-4">
                                                    <Input
                                                        id="survey_name"
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="eg. survey_name"
                                                        name="survey_name"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.survey_name}
                                                        invalid={
                                                            validation.touched.survey_name && validation.errors.survey_name ? true : false
                                                        }
                                                    />
                                                    {validation.touched.survey_name && validation.errors.survey_name ? (
                                                        <FormFeedback type="invalid">{validation.errors.survey_name}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-12 mb-3">
                                                <label
                                                    htmlFor="purpose"
                                                    className="col-form-label"
                                                >
                                                    Survey Purpose
                                                </label>
                                                <div>
                                                    <Input
                                                        id="purpose"
                                                        className="form-control"
                                                        type="textarea"
                                                        rows={5}
                                                        placeholder="eg. The purpose of this interview is to understand the travel experiences of foreign tourists in Japan, to gain a deep understanding of their travel motivations, cultural adaptation and overall satisfaction in Japan"
                                                        name="purpose"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.purpose}
                                                        invalid={
                                                            validation.touched.purpose && validation.errors.purpose ? true : false
                                                        }
                                                    />
                                                    {validation.touched.purpose && validation.errors.purpose ? (
                                                        <FormFeedback type="invalid">{validation.errors.purpose}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>

                                        {/* <Row className="mb-3">
                                            <h5 className="mb-3">Respondent Condition</h5>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="age_range_start"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Age range:
                                                </label>
                                                <div className="col-md-10">
                                                    <Row className="d-flex align-items-center">
                                                        <Col className="col-md-6">
                                                            <InputGroup>
                                                                <div>
                                                                    <Input
                                                                        type="number"
                                                                        id="age_range_start"
                                                                        name="age_range_start"
                                                                        value={validation.values.age_range_start}
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        invalid={
                                                                            validation.touched.age_range_start && validation.errors.age_range_start ? true : false
                                                                        }
                                                                        min={1}
                                                                        max={99}
                                                                    />
                                                                    {validation.touched.age_range_start && validation.errors.age_range_start ? (
                                                                        <FormFeedback type="invalid">{validation.errors.age_range_start}</FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                                <span className="input-group-text">-</span>
                                                                <div>
                                                                    <Input
                                                                        type="number"
                                                                        id="age_range_end"
                                                                        name="age_range_end"
                                                                        value={validation.values.age_range_end}
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        invalid={
                                                                            validation.touched.age_range_end && validation.errors.age_range_end ? true : false
                                                                        }
                                                                        min={1}
                                                                        max={99}
                                                                    />
                                                                    {validation.touched.age_range_start && validation.errors.age_range_start ? (
                                                                        <FormFeedback type="invalid">{validation.errors.age_range_start}</FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="age_range"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Gender:
                                                </label>
                                                <div className="col-md-2">
                                                    <CreatableSelect
                                                        id="gender"
                                                        name="gender"
                                                        value={genderSelected}
                                                        onChange={(selectedOption) => {
                                                            setGenderSelected(selectedOption)
                                                            validation.setFieldValue("gender", selectedOption.value)
                                                            isEmpty(validation.values.gender) ? setGenderInvalid(true) : setGenderInvalid(false)
                                                            // validation.touched.gender && validation.errors.gender
                                                            //     ? setGenderInvalid(true)
                                                            //     : setGenderInvalid(false)
                                                        }}
                                                        // onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        options={genderDropdown}
                                                        // className="basic-single"
                                                        // classNamePrefix="select"
                                                        className={genderInvalidClass}
                                                        aria-invalid={
                                                            validation.touched.gender && validation.errors.gender ? true : false
                                                        }
                                                    />
                                                    {validation.touched.gender && validation.errors.gender ? (
                                                        <FormFeedback type="invalid">{validation.errors.gender}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="age_range"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Nationality:
                                                </label>
                                                <div className="col-md-2">
                                                    <CreatableSelect
                                                        id="nationality"
                                                        name="nationality"
                                                        value={nationalitySelected}
                                                        onChange={(selectedOption) => {
                                                            setNationalitySelected(selectedOption)
                                                            validation.setFieldValue("nationality", selectedOption.value)
                                                            validation.touched.gender && validation.errors.gender
                                                                ? setNationalityInvalid(true)
                                                                : setNationalityInvalid(false)
                                                        }}
                                                        onSubmit={() => {
                                                            validation.touched.gender && validation.errors.gender
                                                                ? setNationalityInvalid(true)
                                                                : setNationalityInvalid(false)
                                                        }}
                                                        options={nationalityDropdown}
                                                        className={nationalityInvalidClass}
                                                        // className="basic-single"
                                                        // classNamePrefix="select"
                                                        aria-invalid={
                                                            validation.touched.nationality && validation.errors.nationality ? true : false
                                                        }
                                                    />
                                                    {validation.touched.nationality && validation.errors.nationality ? (
                                                        <FormFeedback type="invalid">{validation.errors.nationality}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="age_range"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Martial Status:
                                                </label>
                                                <div className="col-md-2">
                                                    <CreatableSelect
                                                        id="martial_status"
                                                        name="martial_status"
                                                        value={martialStatusSelected}
                                                        onChange={(selectedOption) => {
                                                            setMartialStatusSelected(selectedOption)
                                                            validation.setFieldValue("martial_status", selectedOption.value)
                                                        }}
                                                        onSubmit={() => {
                                                            validation.touched.gender && validation.errors.gender
                                                                ? setMartialInvalid(true)
                                                                : setMartialInvalid(false)
                                                        }}
                                                        options={martialStatusDropdown}
                                                        className={martialInvalidClass}
                                                        // className="basic-single"
                                                        // classNamePrefix="select"
                                                        invalid={
                                                            validation.touched.martial_status && validation.errors.martial_status ? true : false
                                                        }
                                                    />
                                                    {validation.touched.martial_status && validation.errors.martial_status ? (
                                                        <FormFeedback type="invalid">{validation.errors.martial_status}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Row>
                                        </Row> */}

                                        <Row className="mb-3">
                                            <h5 className="mb-3">AI add-on Question Criteria</h5>
                                            <Col md="3">
                                                <Nav pills className="flex-column">
                                                    <NavItem>
                                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ "mb-2": true, active: verticalActiveTab === "1", })} onClick={() => { toggleVertical("1"); }}>
                                                            Relevance
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ "mb-2": true, active: verticalActiveTab === "2", })} onClick={() => { toggleVertical("2"); }}>
                                                            Clarity
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ "mb-2": true, active: verticalActiveTab === "3", })} onClick={() => { toggleVertical("3"); }}>
                                                            Engagement Potential
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ "mb-2": true, active: verticalActiveTab === "4", })} onClick={() => { toggleVertical("4"); }}>
                                                            Response Usefulness
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ "mb-2": true, active: verticalActiveTab === "5", })} onClick={() => { toggleVertical("5"); }}>
                                                            Information Gain
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </Col>
                                            <Col md="9">
                                                <TabContent
                                                    activeTab={verticalActiveTab}
                                                    className="text-muted mt-4 mt-md-0"
                                                    id="tab-content"
                                                >
                                                    <TabPane tabId="1">
                                                        <Row className="mb-3">
                                                            <InputGroup>
                                                                <div className="input-group-text">
                                                                    <div className="form-check-primary">
                                                                        <Input
                                                                            className="form-check-input"
                                                                            style={{paddingRight: 0}}
                                                                            type="checkbox"
                                                                            defaultChecked={relevanceInput}
                                                                            value={validation.values.criteria_relevance_checkbox}
                                                                            id="criteria_relevance_checkbox"
                                                                            name="criteria_relevance_checkbox"
                                                                            onChange={(e) => {
                                                                                handleCheckedChange(e)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <Input
                                                                    id="criteria_relevance_text"
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="criteria_relevance_text"
                                                                    placeholder="e.g. Whether the generated question is related to the respondent's answer and the interview topic."
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.criteria_relevance_text}
                                                                    invalid={
                                                                        validation.touched.criteria_relevance_text && validation.errors.criteria_relevance_text ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.criteria_relevance_text && validation.errors.criteria_relevance_text ? (
                                                                    <FormFeedback type="invalid">{validation.errors.criteria_relevance_text}</FormFeedback>
                                                                ) : null}
                                                            </InputGroup>
                                                            {/* <div className="form-check">
                                                                <input
                                                                    className="form-check-input pl-5"
                                                                    type="checkbox"
                                                                    defaultChecked={relevanceInput}
                                                                    value={validation.values.criteria_relevance_checkbox}
                                                                    id="criteria_relevance_checkbox"
                                                                    name="criteria_relevance_checkbox"
                                                                    onChange={(e) => {
                                                                        handleCheckedChange(e)
                                                                    }}
                                                                />
                                                            </div> */}
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Label
                                                                htmlFor="criteria_relevance_context"
                                                                className="col-md-2 col-form-label"
                                                            >
                                                                Context:
                                                            </Label>
                                                            <div className="col">
                                                                <Input
                                                                    id="criteria_relevance_context"
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Fill text"
                                                                    name="criteria_relevance_context"
                                                                    value={validation.values.criteria_relevance_context}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    invalid={
                                                                        validation.touched.criteria_relevance_context && validation.errors.criteria_relevance_context ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.criteria_relevance_context && validation.errors.criteria_relevance_context ? (
                                                                    <FormFeedback type="invalid">{validation.errors.criteria_relevance_context}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Label
                                                                htmlFor="criteria_relevance_good"
                                                                className="col-md-2 col-form-label"
                                                            >
                                                                Good (Score 2):
                                                            </Label>
                                                            <div className="col">
                                                                <Input
                                                                    id="criteria_relevance_good"
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Fill text"
                                                                    name="criteria_relevance_good"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.criteria_relevance_good}
                                                                    invalid={
                                                                        validation.touched.criteria_relevance_good && validation.errors.criteria_relevance_good ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.criteria_relevance_good && validation.errors.criteria_relevance_good ? (
                                                                    <FormFeedback type="invalid">{validation.errors.criteria_relevance_good}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Label
                                                                htmlFor="criteria_relevance_poor"
                                                                className="col-md-2 col-form-label"
                                                            >
                                                                Poor (Score 1):
                                                            </Label>
                                                            <div className="col">
                                                                <Input
                                                                    id="criteria_relevance_poor"
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Fill text"
                                                                    name="criteria_relevance_poor"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.criteria_relevance_poor}
                                                                    invalid={
                                                                        validation.touched.criteria_relevance_poor && validation.errors.criteria_relevance_poor ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.criteria_relevance_poor && validation.errors.criteria_relevance_poor ? (
                                                                    <FormFeedback type="invalid">{validation.errors.criteria_relevance_poor}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Label
                                                                htmlFor="criteria_relevance_bad"
                                                                className="col-md-2 col-form-label"
                                                            >
                                                                Bad (Score 0):
                                                            </Label>
                                                            <div className="col">
                                                                <Input
                                                                    id="criteria_relevance_bad"
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Fill text"
                                                                    name="criteria_relevance_bad"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.criteria_relevance_bad}
                                                                    invalid={
                                                                        validation.touched.criteria_relevance_bad && validation.errors.criteria_relevance_bad ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.criteria_relevance_bad && validation.errors.criteria_relevance_bad ? (
                                                                    <FormFeedback type="invalid">{validation.errors.criteria_relevance_bad}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                    </TabPane>
                                                    <TabPane tabId="2">
                                                        <Row className="mb-3">
                                                            <InputGroup>
                                                                <div className="input-group-text">
                                                                    <div className="form-check-primary">
                                                                        <Input
                                                                            className="form-check-input pl-5"
                                                                            type="checkbox"
                                                                            value={validation.values.criteria_clarity_checkbox}
                                                                            defaultChecked={clarityInput}
                                                                            id="criteria_clarity_checkbox"
                                                                            name="criteria_clarity_checkbox"
                                                                            onChange={(e) => {
                                                                                handleCheckedChange(e)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <Input
                                                                    id="criteria_clarity_text"
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="criteria_clarity_text"
                                                                    placeholder="e.g. Whether the generated question is related to the respondent's answer and the interview topic."
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.criteria_clarity_text}
                                                                    invalid={
                                                                        validation.touched.criteria_clarity_text && validation.errors.criteria_clarity_text ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.criteria_clarity_text && validation.errors.criteria_clarity_text ? (
                                                                    <FormFeedback type="invalid">{validation.errors.criteria_clarity_text}</FormFeedback>
                                                                ) : null}
                                                            </InputGroup>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Label
                                                                htmlFor="criteria_clarity_context"
                                                                className="col-md-2 col-form-label"
                                                            >
                                                                Context:
                                                            </Label>
                                                            <div className="col">
                                                                <Input
                                                                    id="criteria_clarity_context"
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Fill text"
                                                                    name="criteria_clarity_context"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.criteria_clarity_context}
                                                                    invalid={
                                                                        validation.touched.criteria_clarity_context && validation.errors.criteria_clarity_context ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.criteria_clarity_context && validation.errors.criteria_clarity_context ? (
                                                                    <FormFeedback type="invalid">{validation.errors.criteria_clarity_context}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Label
                                                                htmlFor="criteria_clarity_good"
                                                                className="col-md-2 col-form-label"
                                                            >
                                                                Good (Score 2):
                                                            </Label>
                                                            <div className="col">
                                                                <Input
                                                                    id="criteria_clarity_good"
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Fill text"
                                                                    name="criteria_clarity_good"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.criteria_clarity_good}
                                                                    invalid={
                                                                        validation.touched.criteria_clarity_good && validation.errors.criteria_clarity_good ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.criteria_clarity_good && validation.errors.criteria_clarity_good ? (
                                                                    <FormFeedback type="invalid">{validation.errors.criteria_clarity_good}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Label
                                                                htmlFor="criteria_clarity_poor"
                                                                className="col-md-2 col-form-label"
                                                            >
                                                                Poor (Score 1):
                                                            </Label>
                                                            <div className="col">
                                                                <Input
                                                                    id="criteria_clarity_poor"
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Fill text"
                                                                    name="criteria_clarity_poor"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.criteria_clarity_poor}
                                                                    invalid={
                                                                        validation.touched.criteria_clarity_poor && validation.errors.criteria_clarity_poor ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.criteria_clarity_poor && validation.errors.criteria_clarity_poor ? (
                                                                    <FormFeedback type="invalid">{validation.errors.criteria_clarity_poor}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Label
                                                                htmlFor="criteria_clarity_bad"
                                                                className="col-md-2 col-form-label"
                                                            >
                                                                Bad (Score 0):
                                                            </Label>
                                                            <div className="col">
                                                                <Input
                                                                    id="criteria_clarity_bad"
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Fill text"
                                                                    name="criteria_clarity_bad"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.criteria_clarity_bad}
                                                                    invalid={
                                                                        validation.touched.criteria_clarity_bad && validation.errors.criteria_clarity_bad ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.criteria_clarity_bad && validation.errors.criteria_clarity_bad ? (
                                                                    <FormFeedback type="invalid">{validation.errors.criteria_clarity_bad}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                    </TabPane>
                                                    <TabPane tabId="3">
                                                        <Row className="mb-3">
                                                            <InputGroup>
                                                                <div className="input-group-text">
                                                                    <div className="form-check-primary">
                                                                        <Input
                                                                            className="form-check-input pl-5"
                                                                            type="checkbox"
                                                                            defaultChecked={engagementInput}
                                                                            value={validation.values.criteria_engagement_checkbox}
                                                                            id="criteria_engagement_checkbox"
                                                                            name="criteria_engagement_checkbox"
                                                                            onChange={(e) => {
                                                                                handleCheckedChange(e)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <Input
                                                                    id="criteria_engagement_text"
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="criteria_engagement_text"
                                                                    placeholder="e.g. Whether the generated question is related to the respondent's answer and the interview topic."
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.criteria_engagement_text}
                                                                    invalid={
                                                                        validation.touched.criteria_engagement_text && validation.errors.criteria_engagement_text ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.criteria_engagement_text && validation.errors.criteria_engagement_text ? (
                                                                    <FormFeedback type="invalid">{validation.errors.criteria_engagement_text}</FormFeedback>
                                                                ) : null}
                                                            </InputGroup>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Label
                                                                htmlFor="criteria_engagement_context"
                                                                className="col-md-2 col-form-label"
                                                            >
                                                                Context:
                                                            </Label>
                                                            <div className="col">
                                                                <Input
                                                                    id="criteria_engagement_context"
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Fill text"
                                                                    name="criteria_engagement_context"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.criteria_engagement_context}
                                                                    invalid={
                                                                        validation.touched.criteria_engagement_context && validation.errors.criteria_engagement_context ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.criteria_engagement_context && validation.errors.criteria_engagement_context ? (
                                                                    <FormFeedback type="invalid">{validation.errors.criteria_engagement_context}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Label
                                                                htmlFor="criteria_engagement_good"
                                                                className="col-md-2 col-form-label"
                                                            >
                                                                Good (Score 2):
                                                            </Label>
                                                            <div className="col">
                                                                <Input
                                                                    id="criteria_engagement_good"
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Fill text"
                                                                    name="criteria_engagement_good"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.criteria_engagement_good}
                                                                    invalid={
                                                                        validation.touched.criteria_engagement_good && validation.errors.criteria_engagement_good ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.criteria_engagement_good && validation.errors.criteria_engagement_good ? (
                                                                    <FormFeedback type="invalid">{validation.errors.criteria_engagement_good}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Label
                                                                htmlFor="criteria_engagement_poor"
                                                                className="col-md-2 col-form-label"
                                                            >
                                                                Poor (Score 1):
                                                            </Label>
                                                            <div className="col">
                                                                <Input
                                                                    id="criteria_engagement_poor"
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Fill text"
                                                                    name="criteria_engagement_poor"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.criteria_engagement_poor}
                                                                    invalid={
                                                                        validation.touched.criteria_engagement_poor && validation.errors.criteria_engagement_poor ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.criteria_engagement_poor && validation.errors.criteria_engagement_poor ? (
                                                                    <FormFeedback type="invalid">{validation.errors.criteria_engagement_poor}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Label
                                                                htmlFor="criteria_engagement_bad"
                                                                className="col-md-2 col-form-label"
                                                            >
                                                                Bad (Score 0):
                                                            </Label>
                                                            <div className="col">
                                                                <Input
                                                                    id="criteria_engagement_bad"
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Fill text"
                                                                    name="criteria_engagement_bad"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.criteria_engagement_bad}
                                                                    invalid={
                                                                        validation.touched.criteria_engagement_bad && validation.errors.criteria_engagement_bad ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.criteria_engagement_bad && validation.errors.criteria_engagement_bad ? (
                                                                    <FormFeedback type="invalid">{validation.errors.criteria_engagement_bad}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                    </TabPane>
                                                    <TabPane tabId="4">
                                                        <Row className="mb-3">
                                                            <InputGroup>
                                                                <div className="input-group-text">
                                                                    <div className="form-check-primary">
                                                                        <Input
                                                                            className="form-check-input pl-5"
                                                                            type="checkbox"
                                                                            defaultChecked={respusefInput}
                                                                            value={validation.values.criteria_response_usefulness_checkbox}
                                                                            id="criteria_response_usefulness_checkbox"
                                                                            name="criteria_response_usefulness_checkbox"
                                                                            onChange={(e) => {
                                                                                handleCheckedChange(e)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <Input
                                                                    id="criteria_response_usefulness_text"
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="criteria_response_usefulness_text"
                                                                    placeholder="e.g. Whether the generated question is related to the respondent's answer and the interview topic."
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.criteria_response_usefulness_text}
                                                                    invalid={
                                                                        validation.touched.criteria_response_usefulness_text && validation.errors.criteria_response_usefulness_text ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.criteria_response_usefulness_text && validation.errors.criteria_response_usefulness_text ? (
                                                                    <FormFeedback type="invalid">{validation.errors.criteria_response_usefulness_text}</FormFeedback>
                                                                ) : null}
                                                            </InputGroup>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Label
                                                                htmlFor="criteria_response_usefulness_context"
                                                                className="col-md-2 col-form-label"
                                                            >
                                                                Context:
                                                            </Label>
                                                            <div className="col">
                                                                <Input
                                                                    id="criteria_response_usefulness_context"
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Fill text"
                                                                    name="criteria_response_usefulness_context"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.criteria_response_usefulness_context}
                                                                    invalid={
                                                                        validation.touched.criteria_response_usefulness_context && validation.errors.criteria_response_usefulness_context ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.criteria_response_usefulness_context && validation.errors.criteria_response_usefulness_context ? (
                                                                    <FormFeedback type="invalid">{validation.errors.criteria_response_usefulness_context}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Label
                                                                htmlFor="criteria_response_usefulness_good"
                                                                className="col-md-2 col-form-label"
                                                            >
                                                                Good (Score 2):
                                                            </Label>
                                                            <div className="col">
                                                                <Input
                                                                    id="criteria_response_usefulness_good"
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Fill text"
                                                                    name="criteria_response_usefulness_good"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.criteria_response_usefulness_good}
                                                                    invalid={
                                                                        validation.touched.criteria_response_usefulness_good && validation.errors.criteria_response_usefulness_good ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.criteria_response_usefulness_good && validation.errors.criteria_response_usefulness_good ? (
                                                                    <FormFeedback type="invalid">{validation.errors.criteria_response_usefulness_good}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Label
                                                                htmlFor="criteria_response_usefulness_poor"
                                                                className="col-md-2 col-form-label"
                                                            >
                                                                Poor (Score 1):
                                                            </Label>
                                                            <div className="col">
                                                                <Input
                                                                    id="criteria_response_usefulness_poor"
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Fill text"
                                                                    name="criteria_response_usefulness_poor"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.criteria_response_usefulness_poor}
                                                                    invalid={
                                                                        validation.touched.criteria_response_usefulness_poor && validation.errors.criteria_response_usefulness_poor ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.criteria_response_usefulness_poor && validation.errors.criteria_response_usefulness_poor ? (
                                                                    <FormFeedback type="invalid">{validation.errors.criteria_response_usefulness_poor}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Label
                                                                htmlFor="criteria_response_usefulness_bad"
                                                                className="col-md-2 col-form-label"
                                                            >
                                                                Bad (Score 0):
                                                            </Label>
                                                            <div className="col">
                                                                <Input
                                                                    id="criteria_response_usefulness_bad"
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Fill text"
                                                                    name="criteria_response_usefulness_bad"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.criteria_response_usefulness_bad}
                                                                    invalid={
                                                                        validation.touched.criteria_response_usefulness_bad && validation.errors.criteria_response_usefulness_bad ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.criteria_response_usefulness_bad && validation.errors.criteria_response_usefulness_bad ? (
                                                                    <FormFeedback type="invalid">{validation.errors.criteria_response_usefulness_bad}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                    </TabPane>
                                                    <TabPane tabId="5">
                                                        <Row className="mb-3">
                                                            <InputGroup>
                                                                <div className="input-group-text">
                                                                    <div className="form-check-primary">
                                                                        <Input
                                                                            className="form-check-input pl-5"
                                                                            type="checkbox"
                                                                            defaultChecked={infogainInput}
                                                                            value={validation.values.criteria_information_gain_checkbox}
                                                                            id="criteria_information_gain_checkbox"
                                                                            name="criteria_information_gain_checkbox"
                                                                            onChange={(e) => {
                                                                                handleCheckedChange(e)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <Input
                                                                    id="criteria_information_gain_text"
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="criteria_information_gain_text"
                                                                    placeholder="e.g. Whether the generated question is related to the respondent's answer and the interview topic."
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.criteria_information_gain_text}
                                                                    invalid={
                                                                        validation.touched.criteria_information_gain_text && validation.errors.criteria_information_gain_text ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.criteria_information_gain_text && validation.errors.criteria_information_gain_text ? (
                                                                    <FormFeedback type="invalid">{validation.errors.criteria_information_gain_text}</FormFeedback>
                                                                ) : null}
                                                            </InputGroup>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Label
                                                                htmlFor="criteria_information_gain_context"
                                                                className="col-md-2 col-form-label"
                                                            >
                                                                Context:
                                                            </Label>
                                                            <div className="col">
                                                                <Input
                                                                    id="criteria_information_gain_context"
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Fill text"
                                                                    name="criteria_information_gain_context"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.criteria_information_gain_context}
                                                                    invalid={
                                                                        validation.touched.criteria_information_gain_context && validation.errors.criteria_information_gain_context ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.criteria_information_gain_context && validation.errors.criteria_information_gain_context ? (
                                                                    <FormFeedback type="invalid">{validation.errors.criteria_information_gain_context}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Label
                                                                htmlFor="criteria_information_gain_good"
                                                                className="col-md-2 col-form-label"
                                                            >
                                                                Good (Score 2):
                                                            </Label>
                                                            <div className="col">
                                                                <Input
                                                                    id="criteria_information_gain_good"
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Fill text"
                                                                    name="criteria_information_gain_good"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.criteria_information_gain_good}
                                                                    invalid={
                                                                        validation.touched.criteria_information_gain_good && validation.errors.criteria_information_gain_good ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.criteria_information_gain_good && validation.errors.criteria_information_gain_good ? (
                                                                    <FormFeedback type="invalid">{validation.errors.criteria_information_gain_good}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Label
                                                                htmlFor="criteria_information_gain_poor"
                                                                className="col-md-2 col-form-label"
                                                            >
                                                                Poor (Score 1):
                                                            </Label>
                                                            <div className="col">
                                                                <Input
                                                                    id="criteria_information_gain_poor"
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Fill text"
                                                                    name="criteria_information_gain_poor"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.criteria_information_gain_poor}
                                                                    invalid={
                                                                        validation.touched.criteria_information_gain_poor && validation.errors.criteria_information_gain_poor ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.criteria_information_gain_poor && validation.errors.criteria_information_gain_poor ? (
                                                                    <FormFeedback type="invalid">{validation.errors.criteria_information_gain_poor}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Label
                                                                htmlFor="criteria_information_gain_bad"
                                                                className="col-md-2 col-form-label"
                                                            >
                                                                Bad (Score 0):
                                                            </Label>
                                                            <div className="col">
                                                                <Input
                                                                    id="criteria_information_gain_bad"
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Fill text"
                                                                    name="criteria_information_gain_bad"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.criteria_information_gain_bad}
                                                                    invalid={
                                                                        validation.touched.criteria_information_gain_bad && validation.errors.criteria_information_gain_bad ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.criteria_information_gain_bad && validation.errors.criteria_information_gain_bad ? (
                                                                    <FormFeedback type="invalid">{validation.errors.criteria_information_gain_bad}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                    </TabPane>
                                                </TabContent>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label className="h5" htmlFor="remarks">Remarks</Label>
                                            <div>
                                                <Input
                                                    type="textarea"
                                                    rows="5"
                                                    id="remarks"
                                                    name="remarks"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.remarks}
                                                    invalid={
                                                        validation.touched.remarks && validation.errors.remarks ? true : false
                                                    }
                                                />
                                                {validation.touched.remarks && validation.errors.remarks ? (
                                                    <FormFeedback type="invalid">{validation.errors.remarks}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label className="h5" htmlFor="rules">User’s Answer Evaluation Criteria</Label>
                                            <div>Rules</div>
                                            <div>
                                                <Input
                                                    type="textarea"
                                                    rows="5"
                                                    id="rules"
                                                    name="rules"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.rules}
                                                    invalid={
                                                        validation.touched.rules && validation.errors.rules ? true : false
                                                    }
                                                />
                                                {validation.touched.rules && validation.errors.rules ? (
                                                    <FormFeedback type="invalid">{validation.errors.rules}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col>
                                                <div style={{display: "flex", justifyContent: "center"}}>
                                                    {/* <Link to={"/questionaire/create"} color="primary" className="btn btn-primary waves-effect waves-light">Draft Questionaire</Link> */}
                                                    <Button type="submit" color="primary" className="btn btn-primary waves-effect waves-light">
                                                        Update Questionnaire {sendQuestion && <Spinner size="sm" className="ms-2" color="light" setLoading={setSendQuestion} />}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

// QuestionaireCategoryEdit.propTypes = {
//     t: PropTypes.any,
// };


export default withTranslation()(QuestionaireCategoryEdit)
