// import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"

import {
    Col,
    Row,
    Card,
    CardBody,
    // CardTitle,
    // CardSubtitle,
    // CardImg,
    // CardText,
    CardHeader,
    // CardImgOverlay,
    // CardFooter,
    // CardDeck,
    Container,
    Label,
    Input,
    Form,
    Nav, NavItem, NavLink, TabContent, TabPane,
    Button,
    InputGroup,
    Spinner,
    Table,
} from "reactstrap";

import { ReactSortable, Sortable, Swap } from "react-sortablejs";

import Breadcrumbs from '../../components/Common/Breadcrumb2';
import { withTranslation } from "react-i18next";
import '../../components/Common/ts-css.css'
import moment from "moment";

import { get, post, put } from "helpers/api_helper";
import { checkScope } from "helpers/utility_helper";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { createQuestionaireQuestion, createQuestionaireQuestionReset, apiError } from "../../store/actions";
import { createQuestionaireCategory, createQuestionaireCategoryReset } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import Select from "react-select"
import CreatableSelect from 'react-select/creatable';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import Spinners from "components/Common/Spinner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NoQuestionModal from "components/Common/NoQuestionModal";

import { isEmpty } from "lodash";
import { inArray } from "helpers/utility_helper";
import { number } from "prop-types";
import GenerateQuiz from "./GenerateQuiz";
import QuizTemplate from "./QuizTemplate";
import QuizTable from "./QuizTable";
import useQuestionnaireStore from "../../zustand/store/QuestionnaireModule";
// import QuizAiTemplate from "./QuizAiTemplate";



const QuestionaireTemplate = ({
    pageView = 'create'
}) => {

    const location = useLocation();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { categoryId } = useParams();

    // CENTER STATE
    const questionnaireStore = useQuestionnaireStore(); // STATE
    const { form1, form2 } = useQuestionnaireStore(state => state); // STATE
    const { setQuestionSet } = useQuestionnaireStore(); // ACTION

    // PARENT STATE
    // const [ questionType, setQuestionType ] = useState('multi');
    const [ categoryDetailLoading, setCategoryDetailLoading ] = useState(true);
    const [ questionnaireUrl, setQuestionnaireUrl ] = useState("");
    const [ bucketId, setBucketId ] = useState(null);



    const pushToTableQuiz = (newValue = {}) => {
        let tmpData = _.cloneDeep(questionnaireStore?.questionSet) ?? []
        tmpData.push(newValue)
        setQuestionSet(tmpData)
    }

    const preFillForm = (formData = {}) => {
        // setQuestionnaireUrl(`${process.env.REACT_APP_FRONTWEB_URL}?uuid=${formData.uuid_link}`)
        setQuestionnaireUrl(`${process.env.REACT_APP_FE_URL}/pdpa/chat/${formData.uuid_link}`)
        setBucketId(formData.id)
        // validation.setFieldValue('id', formData.id)
        // validation.setFieldValue('category_name', formData.category_name)
        // validation.setFieldValue('survey_name', formData.survey_name)
        // validation.setFieldValue('purpose', formData.purpose)

        // validation.setFieldValue('rules', formData.rules)
        // validation.setFieldValue('remarks', formData.remarks)


        // // SET VIEW ARRAY FORM
        // console.log("🚀 ~ fetchContent: ~ formData.criterias:", formData.criterias)
        // let preFillCriterias = []
        // if (formData.criterias && Array.isArray(formData.criterias)) {
        //     preFillCriterias = formData.criterias
        // }
        // validation.setFieldValue('criterias', preFillCriterias)

        // DATA LIST
        setQuestionSet(formData?.question_set ?? [])

    }

    const API = {
        fetchQuestionnaire: async () => {
            const result = await get(process.env.REACT_APP_BACKEND_URL + '/v1/admin/questionnaire/edit/' + categoryId)
            const resData = result.data
            console.log("🚀 ~ fetchContent: ~ resData:", resData)

            // PREFILE INPUT FORM
            preFillForm(resData)
            
            // setContentDetailLoading(false)
        },
        fetchCreateQuestionnaireCategory: async (payload = {}) => {
            try {
                const result = await post(process.env.REACT_APP_BACKEND_URL + '/v1/admin/questionnaire/category/create', {
                    ...payload
                })
                return result
                // setAiQuestion(result.data);
                // setAiQuestionLoading(false)
            } catch (error) {
                console.log('error', error)
                // setAiQuestionLoading(false)
            }
        },
        fetchEditQuestionnaireCategory: async (payload = {}) => {
            try {
                const result = await put(process.env.REACT_APP_BACKEND_URL + '/v1/admin/questionnaire/category/edit', {
                    ...payload
                })
                return result
                // setAiQuestion(result.data);
                // setAiQuestionLoading(false)
            } catch (error) {
                console.log('error', error)
                // setAiQuestionLoading(false)
            }
        },
        fetchCreateQuestionnaire: async (payload = {}) => {
            try {
                const result = await post(process.env.REACT_APP_BACKEND_URL + '/v1/admin/questionnaire/createnew', {
                    ...payload
                })
                navigate('/questionnaire')
                // setAiQuestion(result.data);
                // setAiQuestionLoading(false)
            } catch (error) {
                console.log('error', error)
                // setAiQuestionLoading(false)
            }
        },
        fetchEditQuestionnaire: async (payload = {}) => {
            try {
                const result = await put(process.env.REACT_APP_BACKEND_URL + '/v1/admin/questionnaire/editnew', {
                    ...payload
                })
                navigate('/questionnaire')
                // setAiQuestion(result.data);
                // setAiQuestionLoading(false)
            } catch (error) {
                console.log('error', error)
                // setAiQuestionLoading(false)
            }
        }
    }

    const onSubmitAll = async (value = []) => {
        const tmpPayload1 = JSON.parse(localStorage.getItem('categoryData'))
        const payload1 = {
            ...tmpPayload1
        }

        // ยิง SAVE ตัวแรก
        console.log("🚀 ~ QuestionaireTemplate ~ payload1:", payload1)
        let _categoryId = null
        if (['update','view'].includes(pageView)){
            const response1 =  await API.fetchEditQuestionnaireCategory(payload1)
            console.log("🚀 ~ onSubmitAll ~ response1:", response1)
            _categoryId = response1.data.id
        } else {
            const response1 =  await API.fetchCreateQuestionnaireCategory(payload1)
            console.log("🚀 ~ onSubmitAll ~ response1:", response1)
            _categoryId = response1.data.id
        }

        // ยิง SAVE ตัวที่สอง
        if (_categoryId) {
            const payload2 = {
                category_id: _categoryId,
                id: bucketId,
                question_set: value
            }
            console.log("🚀 ~ QuestionaireTemplate ~ payload2:", payload2)
            if (['update','view'].includes(pageView)){
                API.fetchEditQuestionnaire(payload2)
            } else {
                API.fetchCreateQuestionnaire(payload2)
            }
        }

    }

    useEffect(() => {
        if (['update','view'].includes(pageView)){
            API.fetchQuestionnaire()
        }
    }, [])

    const PageAction = pageView
    document.title = `Questionnaire | ${PageAction} Questionnaire | ` + process.env.REACT_APP_CMS_TITLE

    

    if (categoryDetailLoading) {
        return <Spinners setLoading={setCategoryDetailLoading} />
    }

    return (
        <div className="page-content">
            <Container fluid={false}>
                <Breadcrumbs 
                    title={'Questionnaire'} 
                    breadcrumbItems={[
                        {title: "Questionnaire", link: "questionnaire"}, 
                        {title: "Create Questionnaire", link: "create"},
                    ]}
                />
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                {/* {JSON.stringify(questionType)} */}
                                <Row>
                                    <Col md={6}>
                                        <GenerateQuiz 
                                            questionnaireUrl={questionnaireUrl} 
                                            pageView={pageView}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <QuizTemplate
                                            pageView={pageView}
                                            onCreateQuiz={async (v) => {
                                                console.log('onCreateQuiz', v)
                                                console.log('form1 >>', form1)
                                                // 
                                                
                                                form1.handleSubmit();

                                                const ErrorForm1 = await form1?.validateForm()
                                                if (Object.keys(ErrorForm1).length) {
                                                    return console.log('Err Form1')
                                                }

                                                console.log("🚀 ~ onSubmitAll ~ form1 PASS?.values:", form1?.values)
                                                const updateValue = { 
                                                    topic: form1?.values.topic,
                                                    purpose: form1?.values.purpose,
                                                    keyword: form1?.values.keyword,
                                                    number_of_question: form1?.values.numberOfQuestion,
                                                    ...v
                                                }
                                                pushToTableQuiz(updateValue)
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <hr />
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <QuizTable
                                    pageView={pageView}
                                    onSubmit={onSubmitAll}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
            </Container>
        </div>
    )
}

// QuestionaireCreate.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(QuestionaireTemplate)
