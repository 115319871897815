import {
    UPDATE_QUESTIONAIRE_CONSENT,
    UPDATE_QUESTIONAIRE_CONSENT_SUCCESSFUL,
    UPDATE_QUESTIONAIRE_CONSENT_FAILED,
    UPDATE_QUESTIONAIRE_CONSENT_RESET,
} from "./actionsType"

const initialState = {
    message: null,
    loading: false,
    questionData: null,
    updateError: null,
    updateSuccess: false
}

const questionaireConsent = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_QUESTIONAIRE_CONSENT:
            state = {
                ...state,
                loading: true,
                updateSuccess: false,
                updateError: null,
            }
            break
        case UPDATE_QUESTIONAIRE_CONSENT_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                questionData: action.payload,
                updateSuccess: true,
                updateError: null,
            }
            break
        case UPDATE_QUESTIONAIRE_CONSENT_FAILED:
            state = {
                ...state,
                questionData: null,
                loading: false,
                updateSuccess: false,
                updateError: action.payload,
            }
            break
        case UPDATE_QUESTIONAIRE_CONSENT_RESET:
            state = {
                ...state,
                message: null,
                loading: false,
                updateError: null,
                updateSuccess: false
            }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

export default questionaireConsent