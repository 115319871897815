import PropTypes from "prop-types"
import React, { useEffect, useState, useMemo, useCallback, useRef } from "react"
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    // CardHeader,
    Input,
    // Modal,
    // ModalHeader,
    // ModalBody,
    // ModalFooter,
    // Table,
    // Badge,
    Form,
    Alert,
    FormFeedback,
    Label,
    CardFooter,
    InputGroup,
    Spinner
} from "reactstrap"

import { Link, useLocation, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb2';
import { withTranslation } from "react-i18next";
import '../../components/Common/ts-css.css'

import { get, post } from "helpers/api_helper";
import { checkScope } from "helpers/utility_helper";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, registerUserReset, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import Select from "react-select"
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import moment from "moment";
import fn_helper from "helpers/fn_helper";
import EditorInput from "./EditorInput";

const SUPER_ADMIN_GROUP_OPTION = [
    { id:'yes', name:'Yes' },
    { id:'no', name:'No' },
]

const STATUS_OPTION = [
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Disable' },
]

const DEF_MENU_PER =  [
    {
        menuId: 0,
        status: 'active',
        permission: [],
        hasSubMenu: 'no',
        subMenu: [
            {
                submenuId: 0,
                status: 0,
                permission: [],
            }
        ]
    }
]

const UserGroupTemplate = ({
    pageView = 'create'
}) => {
    const location = useLocation();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const { userGroupId } = useParams();

    // get Menu from path
    const [rootMenu, subMenu, action] = location.pathname.split('/')

    //meta title
    document.title = "Create User Group | " + process.env.REACT_APP_CMS_TITLE;

    // ========== Set Fropdown Option from Server ========== //
    const [groupMenuList, setGroupMenuList] = useState([]);
    const [groupMenuListLoading, setGroupMenuListLoading] = useState(false);
    const [menuPermission, setMenuPermission] = useState(DEF_MENU_PER);
    const [menuPermissionLoaded, setMenuPermissionLoaded] = useState(false);
    const [loadingAPI, setLoadingAPI] = useState(true);

    // ========== Form Validation ========== //
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            groupId: pageView !== 'create' ? userGroupId : undefined,
            groupName: '',
            groupSuperadmin: '',
            groupStatus: 'active',
            
        },
        validationSchema: Yup.object({
            groupName: Yup.string().required("Please Enter User's Name"),
            groupSuperadmin: Yup.string().required("Please Enter User's Name"),
            groupStatus: Yup.string().required("Please Select User Group Status")
        }),
        onSubmit: (values) => {
            console.log("🚀 ~ values:", values)
            const payload = {
                groupId: pageView !== 'create' ? userGroupId : undefined,
                groupName: values.groupName,
                groupSuperadmin: values.groupSuperadmin,
                groupStatus: values.groupStatus,
                menuPermission: cleanMenuPermission(menuPermission)
            }
            console.log("🚀 ~ payload:", payload)

            if (['update','view'].includes(pageView)) {
                API.fetchUpdateUserGroup(payload)
            } 
            else {
                API.fetchCreateUserGroup(payload)
            }
        }
    })

    const cleanMenuPermission = (_menuPermission = []) => {
        let cleanPayload = _.cloneDeep(_menuPermission)
        for (let cp of cleanPayload) {
            console.log("🚀 ~ cleanMenuPermission ~ cp:", cp)
            if (cp.subMenu) {
                delete cp.permission
            } else {
                delete cp.subMenu
            }
        }
        return cleanPayload
    }

    const onPreMenuFill = (menuList = []) => {
        let defMenuPermission = []
        for (let ml of menuList) {
            let tmp = {
                menuId: ml.menu_id,
                status: ml.menu_status,
                permission: ml.system_menu === 'no' ? [] : undefined,
                hasSubMenu: ml.system_menu,
                subMenu: ml.system_menu === 'no' 
                    ? undefined
                    : ml.submenu.map(sml => {
                        return {  
                            menuId: sml.submenu_id, 
                            status: sml.menu_status,
                            permission: []
                        }
                    }),
            }
            defMenuPermission.push(tmp)
        }
        setMenuPermission(defMenuPermission)
        setMenuPermissionLoaded(true)
    }

    const onPreFill = (resData = {}) => {
        validation.setFieldValue('groupId', resData.groupId)
        validation.setFieldValue('groupName', resData.groupName)
        validation.setFieldValue('groupSuperadmin', resData.groupSuperadmin)
        validation.setFieldValue('groupStatus', resData.groupStatus)

        if (resData.menuPermision.length) {
            setMenuPermission(resData.menuPermision)
        }

        setLoadingAPI(false)
    }

    const API = {
        fetchUserGroup: async (payload = {}) => {
            try {
                const result = await get(process.env.REACT_APP_BACKEND_URL + '/v1/admin/systemsettings/user-group-menu/' + payload.userGroupId, {
                    ...payload
                })
                console.log("🚀 ~ fetchUserGroup: ~ fetchUserGroup:", result)
                setLoadingAPI(true)
                onPreFill(result)
                return result
            } catch (error) {
                console.log('error', error)
                // setAiQuestionLoading(false)
            }
        },
        fetchCreateUserGroup: async (payload = {}) => {
            try {
                const result = await post(process.env.REACT_APP_BACKEND_URL + '/v1/admin/systemsettings/createGroupMenuList', {
                    ...payload
                })

                navigate('/systemsettings/usergroup')
                
                return result
            } catch (error) {
                console.log('error', error)
                // setAiQuestionLoading(false)
            }
        },
        fetchUpdateUserGroup: async (payload = {}) => {
            try {
                const result = await post(process.env.REACT_APP_BACKEND_URL + '/v1/admin/systemsettings/editGroupMenuList/' + payload.groupId, {
                    ...payload
                })

                navigate('/systemsettings/usergroup')

                return result
            } catch (error) {
                console.log('error', error)
                // setAiQuestionLoading(false)
            }
        },
    }

    // ========== Form Validation ========== //
    useEffect(() => {
        if (menuPermissionLoaded) {
            if (['update','view'].includes(pageView)) {
                const payload = { userGroupId: userGroupId }
                API.fetchUserGroup(payload)
            }
        }
    }, [menuPermissionLoaded])

    useEffect(() => {
        setGroupMenuListLoading(true)
        const fetchUserList = async () => {
            const result = await get(process.env.REACT_APP_BACKEND_URL + '/v1/admin/systemsettings/groupMenuList')
            setGroupMenuList(result.menuList);
            onPreMenuFill(result.menuList)
            if (pageView === 'create') {
                setLoadingAPI(false)
            }
        };
    
        fetchUserList();
    }, []);

    if (loadingAPI) {
        return <Spinner color="primary" className='position-absolute top-50 start-50' />
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs 
                        title={"Create User Group"} 
                        breadcrumbItems={[
                            {title: "System Setting", link: "systemsettings"}, 
                            {title: "User Group Setting", link: "usergroup"},
                            {title: "Create User Group", link: "add"}
                        ]} />
        
                    {/* Render Form */}
                    <Row>
                        <Col className="col-12">
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                {/* {user && user ? (
                                    <Alert color="success">
                                        Create User Group Successfully
                                    </Alert>
                                ) : null}

                                {registrationError && registrationError ? (
                                    <Alert color="danger">{registrationError}</Alert>
                                ) : null} */}
                                {/* {JSON.stringify(validation.values)} */}
                                <Row>
                                    <Col className="col-8">
                                        <Card>
                                            <h5 className="card-header bg-transparent border-bottom text-uppercase">
                                                <i className='bx bx-cube-alt'></i> Information
                                            </h5>
                                            <CardBody>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="groupName">Group Name <span style={{color:"#F00"}}>*</span></Label>
                                                    <Input
                                                        id="groupName"
                                                        name="groupName"
                                                        className="form-control"
                                                        placeholder="Enter Group Name"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.groupName || ""}
                                                        invalid={
                                                            validation.touched.groupName && validation.errors.groupName ? true : false
                                                        }
                                                    />
                                                    {validation.touched.groupName && validation.errors.groupName ? (
                                                        <FormFeedback type="invalid">{validation.errors.groupName}</FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="groupSuperadmin">Super Admin Group <span style={{color:"#F00"}}>*</span></Label>
                                                    <Select
                                                        id="groupSuperadmin"
                                                        placeholder="Select"
                                                        value={fn_helper.FNFORM.getObjectValue(SUPER_ADMIN_GROUP_OPTION, `${validation.values.groupSuperadmin}`)}
                                                        onChange={(value) => validation.setFieldValue("groupSuperadmin", value.id)}
                                                        getOptionValue={(option) => option.id}
                                                        getOptionLabel={(option) => option.name}
                                                        options={SUPER_ADMIN_GROUP_OPTION}
                                                        className={`select2-selection ${validation.touched.groupSuperadmin && validation.errors.groupSuperadmin ? "input-err" : ""}` }
                                                        isDisabled={pageView === "view"}
                                                    />
                                                    {
                                                        (validation.touched.groupSuperadmin && validation.errors.groupSuperadmin) && (
                                                        <div type="invalid" className="invalid-feedback d-block">{validation.errors.groupSuperadmin}</div>
                                                        )
                                                    }
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="groupName">Access Level<span style={{color:"#F00"}}>*</span></Label>
                                                    <p>Select Menu to allow access</p>
                                                    {/* {JSON.stringify(menuPermission)} */}
                                                    {
                                                        groupMenuList.map((item0, i0) => {
                                                            return (
                                                                <EditorInput
                                                                    key={i0}
                                                                    index={i0}
                                                                    menuName={item0.menu_label}
                                                                    value={menuPermission[i0]}
                                                                    onChange={(newValue, index) => {
                                                                        console.log('EditorInput newValue >>', newValue)
                                                                        console.log('EditorInput index >>', index)
                                                                        console.log("🚀 ~ groupMenuList.map ~ menuPermission[i0]:", menuPermission[i0])

                                                                        let tmpUpdate = _.cloneDeep(menuPermission)
                                                                        tmpUpdate[index] = newValue

                                                                        // เปลี่ยน status ตามตัวแม่
                                                                        if (menuPermission[i0].hasSubMenu === 'yes') {
                                                                            tmpUpdate[index].subMenu = tmpUpdate[index].subMenu.map(item => {
                                                                                item.status = newValue.status
                                                                                return item
                                                                            })
                                                                        } 

                                                                        setMenuPermission(tmpUpdate)
                                                                    }}
                                                                    // hasSubMenu={menuPermission[i0]?.hasSubMenu === 'yes'}
                                                                    hasSubMenu={menuPermission[i0]?.hasSubMenu === 'yes'}
                                                                    subMenuComponent={
                                                                        <>
                                                                            {
                                                                                item0?.submenu?.map((item1, i1) => {
                                                                                    return (
                                                                                        <div style={{ paddingTop: '12px', paddingInline: '8px' }} key={i1}>
                                                                                            <EditorInput
                                                                                                
                                                                                                index={i1}
                                                                                                isSubmenu={true}
                                                                                                menuName={item1.menu_label}
                                                                                                value={menuPermission[i0]?.subMenu[i1]}
                                                                                                onChange={(newValue, index) => {
                                                                                                    console.log('EditorInput newValue >>', newValue)
                                                                                                    console.log('EditorInput index >>', i0)
                                                                                                    console.log('EditorInput index index>>', index)
                    
                                                                                                    let tmpUpdate = _.cloneDeep(menuPermission)
                                                                                                    // console.log("EditorInput before item0 ?.submenu?.map ~ tmpUpdate:", tmpUpdate[i0])
                                                                                                    // console.log("EditorInput before item0 ?.submenu?.map ~ tmpUpdate: index", tmpUpdate[i0].subMenu[index])
                                                                                                    // tmpUpdate[i0].hasSubMenu[index] = newValue
                                                                                                    // console.log("EditorInput after item0 ?.submenu?.map ~ tmpUpdate:", tmpUpdate[i0])
                                                                                                    // 
                                                                                                    for (let tu of tmpUpdate) {
                                                                                                        for (let j in tu.subMenu) {
                                                                                                            // j
                                                                                                            if (j == index) {
                                                                                                                tu.subMenu[j] = newValue
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                    console.log('EditorInput tmpUpdate >>', tmpUpdate)
                                                                                                    setMenuPermission(tmpUpdate)
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    )

                                                                                })
                                                                            }
                                                                        </>

                                                                    }
                                                                />
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col className="col-4">
                                        <Card outline color="primary" className="border">
                                            <h5 className="card-header bg-transparent border-bottom text-primary text-uppercase">
                                                <i className='bx bx-check'></i> Tools Box
                                            </h5>
                                            <CardBody>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="groupStatus">Status <span style={{color:"#F00"}}>*</span></Label>
                                                    <Select
                                                        id="groupStatus"
                                                        placeholder="Select"
                                                        value={fn_helper.FNFORM.getObjectValue(STATUS_OPTION, `${validation.values.groupStatus}`)}
                                                        onChange={(value) => validation.setFieldValue("groupStatus", value.id)}
                                                        getOptionValue={(option) => option.id}
                                                        getOptionLabel={(option) => option.name}
                                                        options={STATUS_OPTION}
                                                        className={`select2-selection ${validation.touched.groupStatus && validation.errors.groupStatus ? "input-err" : ""}` }
                                                        isDisabled={pageView === "view"}
                                                    />
                                                    {
                                                        (validation.touched.groupStatus && validation.errors.groupStatus) && (
                                                        <div type="invalid" className="invalid-feedback d-block">{validation.errors.groupStatus}</div>
                                                        )
                                                    }
                                                </div>
                                            </CardBody>
                                            <CardFooter>
                                                <Row>
                                                    <Col className="text-start">
                                                        <Button color="danger" className="btn btn-danger" onClick={() => navigate(-1)}>Back</Button> 
                                                    </Col>
                                                    <Col className="text-end">
                                                        <Button type="submit" color="primary" className="btn btn-primary">Submit</Button>
                                                    </Col>
                                                </Row>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                    {/* <Col className="col-12">
                                        <Card>
                                            <h5 className="card-header bg-transparent border-bottom text-uppercase">
                                                <i className='bx bx-trending-up'></i> SEO
                                            </h5>
                                            <CardBody>
                                                SEO form
                                            </CardBody>
                                        </Card>
                                    </Col> */}
                                </Row>
                            </Form>
                        </Col>
                        
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

UserGroupTemplate.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(UserGroupTemplate);