import PropTypes from "prop-types"
import React, { useEffect, useState, useMemo, useCallback, useRef } from "react"
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
} from "reactstrap"
//import components
import { withTranslation } from "react-i18next";
import '../../components/Common/ts-css.css'
import { get } from "helpers/api_helper";
import { checkScope } from "helpers/utility_helper";
import moment from "moment";
import fn_helper from "helpers/fn_helper";

const SUPER_ADMIN_GROUP_OPTION = [
    { id:'yes', name:'Yes' },
    { id:'no', name:'No' },
]
const CAN_OPTION = [
    { id:'list', name:'List' },
    { id:'view', name:'View' },
    { id:'create', name:'Create' },
    { id:'update', name:'Update' },
    { id:'delete', name:'Delete' },
    { id:'export', name:'Export' },
]
const PERMISSION_STATUS_OPTION = [
    { id:'active', name:'Active' },
    { id:'disable', name:'Disable' },
]

const EditorInput = ({
    pageView = 'create',
    index = 0,
    menuName = "",
    value = {
        status: 0,
        permission: []
    },
    onChange = () => console.log('EditorInput onChange!'),
    isSubmenu = false,
    hasSubMenu = false,
    subMenuComponent = <div></div>
}) => {

    const onChangeInputOne = (_field = '', _valueUpdate = -1, _value = {} ) => {
        let tmpRows = _.cloneDeep(_value)
        tmpRows[_field] = _valueUpdate
        onChange(tmpRows, index)
    }

    // console.log('permission :', value.permission)
    return (
        <Card className="border" key={index}>
            <CardBody className="border-bottom">
                <Row>
                    <Col sm={3}><h6 style={{ margin: 0 }}>{ isSubmenu ? 'Sub Menu': 'Menu' } - {menuName}</h6></Col>
                    <Col>
                    {
                        PERMISSION_STATUS_OPTION.map((item, i) => {
                            return (
                                <div className="form-check form-check-inline" key={i}>
                                    <Input
                                        className=""
                                        name={`${menuName}-${index}`}
                                        type="radio"
                                        checked={value.status == `${item.id}`}
                                        onClick={(e) => {
                                            const valueUpdate = item.id
                                            onChangeInputOne('status', valueUpdate, value)
                                        }}
                                        readOnly
                                    />
                                    <label className="form-check-label" htmlFor={`${item + i}`}>{item.name}</label>
                                </div>
                            )
                        })
                    }
                    </Col>
                </Row>
            </CardBody>
            
            {
                hasSubMenu === false
                ? (
                    <CardBody className="border-bottom">
                        {/* {JSON.stringify(value)} */}
                        <Row>
                            <Col sm={3}><h6 style={{ margin: 0, color: "#f46a6a" }}>Permission: </h6></Col>
                            <Col sm={8}>
                                <Row>
                                    {
                                        CAN_OPTION.map((item,i) => {
                                            return (
                                                <Col sm={2} key={i}>
                                                    <div className="d-flex gap-2 ">
                                                        <Input
                                                            style={{ marginTop: '0.5px' }}
                                                            className=""
                                                            type="checkbox"
                                                            checked={value?.permission?.includes(item.id)}
                                                            onClick={(el) => {
                                                                let tmpSelected = _.cloneDeep(value.permission)
                                                                let valueUpdate = fn_helper.FNFORM.handleSelectChange(tmpSelected, item.id)
                                                                onChangeInputOne('permission', valueUpdate, value)
                                                            }}
                                                            readOnly
                                                        />
                                                        <h6 style={{ margin: 0 }}>{item.name}</h6>
                                                    </div>
                                                </Col>
                                            ) 
                                        })
                                    }
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                )
                : subMenuComponent
            }
        </Card>
    );
};

EditorInput.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(EditorInput);