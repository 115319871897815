// import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Col, Row, Input, Form, FormFeedback, Button, Spinner, Table } from "reactstrap";
import { Label, InputGroup, Card, CardBody, Tooltip } from "reactstrap";
import * as Yup from "yup";
import { useFormik, FieldArray, FormikProvider } from "formik";
import Select from "react-select"
import { withTranslation } from "react-i18next";
import '../../components/Common/ts-css.css'
import fn_helper from "helpers/fn_helper";
import useQuestionnaireStore from "../../zustand/store/QuestionnaireModule";

const QuizStatic = ({
    typeForm = 'openend',
    onSubmit = () => console.log('onSubmit!!')
}) => {
        // CENTER STATE
    // const { questionSet = [] } = useQuestionnaireStore(state => state); // STATE
    const { setForm2 } = useQuestionnaireStore(); // ACTION

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipOpen2, setTooltipOpen2] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);
    const toggle2 = () => setTooltipOpen2(!tooltipOpen2);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            ai_addon_questions: false,
            ask_number_question: '0',

            question_text: '',
            evaluation_criteria: false,
            relevance_score: 0,
            detail_score: 0,
            average_score: 0,

        },
        validationSchema: Yup.object({
            question_text: Yup.string().max(512).required("Please Enter Question"),
            ai_addon_questions: Yup.string(),
        }),
        onSubmit: (values) => {
            values.question_type = typeForm
            
            if (values.ai_addon_questions === false) {
                values.ai_addon_questions = false
                values.ask_number_question = 0
            }
            console.log('values >>', values)
            onSubmit(values)
        }
    })

    // Custom handleChange function
    const customHandleChange = (e, index) => {
        const { name, value } = e.target;

        // Example: Perform custom logic, e.g., transforming value
        let transformedValue = value; // Example transformation

        // Update Formik's field value with transformed value
        validation.setFieldValue(name, transformedValue);

        // Call Formik's handleChange
        validation.handleChange(e);
    };


    const fnInputMin0Max100 = (valueInput = 0) => {
        let valueUpdate = valueInput
        if (valueInput > 100) {
            valueUpdate = 100
        } else if (valueInput < 0 || Number.isNaN(valueInput)) {
            valueUpdate = 0
        }
        return valueUpdate
    }

    useEffect(() => {
        console.log("🚀 ~ useEffect ~ validation: form2 B", validation)
        setForm2(validation)
    }, [])

    const ddSelect = [...Array(6)].map((item, i) => ({ id: `${i}`, name: `${i}` }))

    

    return(
        <>
            <FormikProvider value={validation}>
                <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    {/* Question */}
                    <Row className="mb-3">
                        <Col>
                            <Label
                                htmlFor="example-search-input"
                                className="col-form-label"
                                >
                                <h5>Question:</h5>
                            </Label>
                            <div>
                                <Input
                                    id="question_text"
                                    className="form-control"
                                    type="text"
                                    placeholder="eg. What do you do?"
                                    name="question_text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.question_text}
                                    invalid={
                                        validation.touched.question_text && validation.errors.question_text ? true : false
                                    }
                                />
                                {validation.touched.question_text && validation.errors.question_text ? (
                                    <FormFeedback type="invalid">{validation.errors.question_text}</FormFeedback>
                                ) : null}
                                
                            </div>

                        </Col>
                    </Row>
                    {/* AI add-on Questions */}
                    <Row className="mb-3">
                        <Col className="col-md-12">
                            <div className="form-check form-switch mb-3" >
                                <Input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="ai_addon_questions"
                                    name="ai_addon_questions"
                                    // onChange={validation.handleChange}
                                    // onBlur={validation.handleBlur}
                                    checked={validation.values?.ai_addon_questions}
                                    onClick={(e)=> {
                                        const value = validation.values.ai_addon_questions
                                        const name = `ai_addon_questions`
                                        validation.setFieldValue(name, !value);
                                    }}
                                    readOnly
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="ai_addon_questions"
                                >
                                    <h5>
                                        <span id="tooltip-ai-add-on">AI add-on Questions</span>
                                    </h5>
                                </label>
                            </div>
                        </Col>
                        <Col className="col-mb-12">
                            <Card outline color="secondary" className="border bg-secondary-subtle">
                                {/* <CardHeader className="bg-transparent">
                                
                                </CardHeader> */}
                                <CardBody>
                                    <Row className="row-cols-md-auto g-3 align-items-center">
                                        <Col md={9}>
                                            <div className="align-middle">
                                                <Label className="my-0 text-secondary">
                                                    <i className="mdi mdi-bullseye-arrow me-3" />How Many Questions would you like the AI to suggest for the survey?
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col className="align-middle">
                                            <Select
                                                placeholder="Select"
                                                value={fn_helper.FNFORM.getObjectValue(ddSelect, `${validation.values.ask_number_question}`)}
                                                onChange={(value) => validation.setFieldValue("ask_number_question", value.id)}
                                                getOptionValue={(option) => option.id}
                                                getOptionLabel={(option) => option.name}
                                                options={ddSelect}
                                                className={`select2-selection ${validation.touched.ask_number_question && validation.errors.ask_number_question ? "input-err" : ""}` }
                                                isDisabled={validation.values.ai_addon_questions === false}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        
                    <Col className="col-md-12">
                    {/* {JSON.stringify(validation.values)} */}
                        <div className="form-check form-switch" >
                            <Input
                                type="checkbox"
                                className="form-check-input"
                                id="evaluation_criteria"
                                name="evaluation_criteria"
                                // onChange={validation.handleChange}
                                // onBlur={validation.handleBlur}
                                checked={validation.values?.evaluation_criteria}
                                onClick={(e)=> {
                                    const value = validation.values.evaluation_criteria
                                    const name = `evaluation_criteria`
                                    validation.setFieldValue(name, !value);
                                }}
                                readOnly
                            />
                            <label
                                className="form-check-label"
                                htmlFor="evaluation_criteria"
                            >
                                <h5>
                                    <span>User’s Answer Evaluation Criteria</span>
                                    <i className="bx bxs-info-circle hoverShow" id="tooltip-answer-evaluation" style={{ marginLeft: 4 }}></i>
                                </h5>
                                <Tooltip 
                                    placement="top" 
                                    isOpen={tooltipOpen2} 
                                    target="tooltip-answer-evaluation" 
                                    toggle={toggle2}
                                    >
                                    <div>AI will evaluate respondent’s answers for each question. Please input the weight of the Relevance and the detail score and the average score for passing this question.</div>
                                </Tooltip>
                        </label>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col className="col-md-1"></Col>
                    <Col className="col-md-11">
                        <Row>
                            <label
                                htmlFor="relevance_score"
                                className="col-md-5 col-form-label"
                                >
                                Relevance Score (0-100%): <em className="fw-light">Scores how relevant the respondent's answer is to the question</em>
                            </label>
                            <div className="col-md-3">
                                <InputGroup>
                                    <Input
                                        id="relevance_score"
                                        name="relevance_score"
                                        className="form-control"
                                        type="number"
                                        onChange={(e)=> {
                                            const valueInput = e.target.valueAsNumber
                                            let valueUpdate = fnInputMin0Max100(valueInput)
                                            
                                            validation.setFieldValue(`relevance_score`, valueUpdate);
                                            validation.setFieldValue(`detail_score`, 100 - valueUpdate);
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.relevance_score}
                                        invalid={
                                            validation.touched.relevance_score && validation.errors.relevance_score ? true : false
                                        }
                                        disabled={validation.values.evaluation_criteria === false}
                                        min={0}
                                        max={100}
                                    />
                                    <span className="input-group-append">
                                        <span className="input-group-text">%</span>
                                    </span>
                                </InputGroup>
                            </div>
                        </Row>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col className="col-md-1"></Col>
                    <Col className="col-md-11">
                        <Row>
                            <label
                                htmlFor="detail_score"
                                className="col-md-5 col-form-label"
                                >
                                Detail Score (0-100%): <em className="fw-light">Scores the level of detail in the respondent's answer</em>
                            </label>
                            <div className="col-md-3">
                                <InputGroup>
                                    <Input
                                        id="detail_score"
                                        name="detail_score"
                                        className="form-control"
                                        type="number"
                                        onChange={(e)=> {
                                            const valueInput = e.target.valueAsNumber
                                            let valueUpdate = fnInputMin0Max100(valueInput)
                                            
                                            validation.setFieldValue(`detail_score`, valueUpdate);
                                            validation.setFieldValue(`relevance_score`, 100 - valueUpdate);
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.detail_score}
                                        invalid={
                                            validation.touched.detail_score && validation.errors.detail_score ? true : false
                                        }
                                        disabled={validation.values.evaluation_criteria === false}
                                        min={0}
                                        max={100}
                                    />
                                    <span className="input-group-append">
                                        <span className="input-group-text">%</span>
                                    </span>
                                </InputGroup>
                            </div>
                        </Row>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col className="col-md-1"></Col>
                    <Col className="col-md-11">
                        <Row>
                            <label
                                htmlFor="average_score"
                                className="col-md-5 col-form-label"
                                >
                                Average Score (1-10): <em className="fw-light">The minimum score needed to pass a question.</em>
                            </label>
                            <div className="col-md-3">
                                <Input
                                    id="average_score"
                                    name="average_score"
                                    className="form-control"
                                    type="number"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.average_score}
                                    invalid={
                                        validation.touched.average_score && validation.errors.average_score ? true : false
                                    }
                                    disabled={validation.values.evaluation_criteria === false}
                                    min={0}
                                    max={10}
                                />
                            </div>
                        </Row>
                    </Col>
                </Row>
                    <Row className="mb-3">
                        <Col style={{display: "flex", justifyContent: "right"}}>
                            <Button type="submit" color="primary" className="btn btn-primary" 
                                onClick={()=> {
                                    document.getElementById('AI_GEN_QUIZ_BTN').click()
                                }}
                            >Save Question</Button>
                        </Col>
                    </Row>
                </Form>
            </FormikProvider>
        </>
    )
}
export default withTranslation()(QuizStatic)