import React from "react";
import { Navigate } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

// TS Route
import UserList from "../pages/Systemsettings/UserList"
import UserAdd  from "../pages/Systemsettings/UserAdd"
import UserEdit from "../pages/Systemsettings/UserEdit"

// Questionaire Page
import QuestionaireCategoryList from "pages/Questionaire/CategoryList";
import QuestionaireCategoryCreate from "pages/Questionaire/QuestionaireCategory";
import QuestionaireCategoryEdit from "pages/Questionaire/QuestionaireCategoryEdit";

import QuestionaireList from "pages/Questionaire/QuestionaireList";
import QuestionaireCreate from "pages/Questionaire/QuestionaireCreate";
import QuestionaireEdit from "pages/Questionaire/QuestionaireEdit";


// Frontweb
import QuestionnaireFrontweb from "pages/Frontweb/QuestionnaireFrontweb";
import TestForm from "pages/Other/TestForm";
import QuestionaireCategoryTemplate from "pages/Questionaire/QuestionaireCategoryTemplate";
import QuestionaireTemplate from "pages/Questionaire/QuestionaireTemplate";
import QuestionairePdpa from "../pages/Questionaire/QuestionairePdpa";
import TalkComponent from "pages/Webchat/TalkComponent";
import UserGroupList from "pages/Systemsettings/UserGroupList";
import UserGroupTemplate from "pages/Systemsettings/UserGroupTemplate";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/systemsettings/usersetting", component: <UserList /> },
  { path: "/systemsettings/usersetting/useradd",  component: <UserAdd /> },
  { path: "/systemsettings/usersetting/useredit/:userId", component: <UserEdit /> },
  { path: "/systemsettings/usergroup", component: <UserGroupList /> },
  { path: "/systemsettings/usergroup/add", component: <UserGroupTemplate pageView="create"/> },
  { path: "/systemsettings/usergroup/edit/:userGroupId", component: <UserGroupTemplate pageView="update"/> },

  // for AI Shopping
  { path: "/questionnaire", component: <QuestionaireCategoryList /> },
  { path: "/questionnaire/category", component: <QuestionaireCategoryList /> },
  // { path: "/questionnaire/category/create", component: <QuestionaireCategoryCreate /> },
  // { path: "/questionnaire/category/edit/:categoryId", component: <QuestionaireCategoryEdit /> },

  { path: "/questionnaire/category/create", component: <QuestionaireCategoryTemplate pageView="create"/> },
  { path: "/questionnaire/category/edit/:categoryId", component: <QuestionaireCategoryTemplate pageView="update"/> },

  { path: "/questionnaire/list/:categoryName", component: <QuestionaireList /> },
  // { path: "/questionnaire/create/:categoryId", component: <QuestionaireCreate /> },
  // { path: "/questionnaire/create", component: <QuestionaireCreate /> },
  { path: "/questionnaire/create", component: <QuestionaireTemplate pageView="create" /> },
  // { path: "/questionnaire/edit/:oldCategoryId/:newCategoryId", component: <QuestionaireEdit /> },
  // { path: "/questionnaire/edit/:categoryId", component: <QuestionaireEdit /> },
  { path: "/questionnaire/edit/:categoryId", component: <QuestionaireTemplate pageView="update" /> },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/test", component: <TestForm /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  {path: "/make/questionnaire/:questionnaireUUID", component: <QuestionnaireFrontweb />},
  {path: "/pdpa/chat/:questionnaireUUID", component: <QuestionairePdpa />},
  {path: "/webchat/:questionnaireUUID", component: <TalkComponent />}
];

export { authProtectedRoutes, publicRoutes };
