// import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Col, Row, Input, Form, FormFeedback, Button, Spinner, Table } from "reactstrap";
import { Label, InputGroup, Card, CardBody } from "reactstrap";
import * as Yup from "yup";
import { useFormik, FieldArray, FormikProvider } from "formik";
import Select from "react-select"
import { withTranslation } from "react-i18next";
import '../../components/Common/ts-css.css'
import fn_helper from "helpers/fn_helper";
import Spinners from "components/Common/Spinner";
import { get, post } from "helpers/api_helper";
import QuizChoice from "./QuizChoice";
import QuizStatic from "./QuizStatic";


const DD_OPTION_QUIZ_TYPE = [
    { id: 'multi', name: 'Multiple Choice' },
    { id: 'single', name: 'Single Answer' },
    { id: 'openend', name: 'Open-end' },
    { id: 'image', name: 'Image Upload' },
]

const QuizTemplate = ({
    pageView = 'create',
    // questionType = 'multi',
    // onChangeQuestionType = (v) => console.log('onChangeQuestionType !'),
    onCreateQuiz = (v) => console.log('onCreateQuiz !')
}) => {

    const [ questionType, setQuestionType ] = useState('multi');
    
    return(
        <>
            <h5 className="mb-3">Type of Question</h5>

            <Row className="mb-3">
                <div className="col-md-12">
                    <Select
                        placeholder="Select"
                        value={fn_helper.FNFORM.getObjectValue(DD_OPTION_QUIZ_TYPE, `${questionType}`)}
                        onChange={(selectValue) => {
                            setQuestionType(selectValue.id)
                        }}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        options={DD_OPTION_QUIZ_TYPE}
                        // className={`select2-selection ${validation.touched.ask_number_question && validation.errors.ask_number_question ? "input-err" : ""}` }
                        isDisabled={pageView === "view"}
                    />
                    {
                        false && (
                            <div type="invalid" className="invalid-feedback d-block">Require Field</div>
                        )
                    }
                </div>
            </Row>
            {
                ['multi', 'single'].includes(questionType) &&  (
                    <QuizChoice 
                        typeForm={questionType} 
                        onSubmit={(value) => {
                            console.log('onSubmit value >>', value)
                            let tmpValue = {
                                question_type: questionType,
                                ...value,
                                goto: 'Next Question'
                            }
                            onCreateQuiz(tmpValue)
                        }}
                    />
                )
            }
            {
                ['openend', 'image'].includes(questionType) &&  (
                    <QuizStatic
                        typeForm={questionType} 
                        onSubmit={(value) => {
                            console.log('onSubmit value >>', value)
                            let tmpValue = {
                                question_type: questionType,
                                ...value,
                                goto: 'Next Question'
                            }
                            onCreateQuiz(tmpValue)
                        }}
                    />
                )
            }

        </>
    )
}

export default withTranslation()(QuizTemplate)