import { APIClient } from "./api_helper";
import * as url from "./url_helper"
import { del, get, post, put } from "./api_helper";
import * as utility from "helpers/utility_helper"

const login = user => post(url.POST_ADMIN_LOGIN, user);
const checkAuth = () => get(url.POST_ADMIN_CHECK_AUTH);

const getSidebar = () => get(url.GET_ADMIN_SIDEBAR_MENU);

const createUser = user => post(url.POST_CREATE_USER, user);
const updateUser = user => put(url.PUT_CREATE_USER, user);

const createQCate = data => post(url.POST_CREATE_QUESTIONAIRE_CATEGORY, data);
const updateQCate = data => put(url.PUT_CREATE_QUESTIONAIRE_CATEGORY, data);

const createQQuestion = data => post(url.POST_CREATE_QUESTIONAIRE_QUESTION, data);
const updateQQuestion = data => put(url.PUT_CREATE_QUESTIONAIRE_QUESTION, data);
// const deleteQQuestion = data => del(url.DEL_CREATE_QUESTIONAIRE_QUESTION, data);

const updateQConsent = data => put(url.PUT_UPDATE_QUESTIONAIRE_CONSENT, data);

export {
    login,
    checkAuth,
    getSidebar,
    createUser,
    updateUser,
    createQCate,
    updateQCate,
    createQQuestion,
    updateQQuestion,
    updateQConsent,
}