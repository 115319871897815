
const AgreementMessage = `Before completing the survey, please review the Program Details and Privacy Policy. After understanding them, make your selection. By clicking "Join the event," you consent to our processing your personal data as outlined. If you disagree, please do not proceed. We won't notify you individually of policy changes.`
const ActivityRules = `<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="TextRun SCXW13188931 BCX8" lang="EN-US" xml:lang="EN-US" data-contrast="auto"><span class="NormalTextRun SCXW13188931 BCX8">Thank you for joining our media survey! </span><span class="NormalTextRun SCXW13188931 BCX8">We'll</span><span class="NormalTextRun SCXW13188931 BCX8"> ask about your media usage, and participants who meet the criteria* and complete the survey (about 10-20 minutes) can win prizes worth up to 1 million VND. Winners will be randomly selected from those who complete the survey, and prizes will be sent via </span><span class="NormalTextRun SpellingErrorV2Themed SCXW13188931 BCX8">MoMo</span><span class="NormalTextRun SCXW13188931 BCX8"> within </span><span class="NormalTextRun AdvancedProofingIssueV2Themed SCXW13188931 BCX8">30 days</span><span class="NormalTextRun SCXW13188931 BCX8"> after the activity ends.</span></span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:true,&quot;335551550&quot;:2,&quot;335551620&quot;:2,&quot;335559738&quot;:240,&quot;335559739&quot;:240}">&nbsp;</span></p>
</div>
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">Prizes:</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:true,&quot;335551550&quot;:2,&quot;335551620&quot;:2,&quot;335559738&quot;:240,&quot;335559739&quot;:240}">&nbsp;</span></p>
</div>
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<div class="TableContainer Ltr SCXW13188931 BCX8">
<div id="{bb0038c9-4329-4b90-86ef-785e530c4437}{63}" class="WACAltTextDescribedBy SCXW13188931 BCX8" aria-hidden="true"></div>
<table class="Table Ltr TableWordWrap SCXW13188931 BCX8" border="1" data-tablestyle="MsoNormalTable" data-tablelook="1696" aria-rowcount="6">
<tbody class="SCXW13188931 BCX8">
<tr class="TableRow SCXW13188931 BCX8" role="row" aria-rowindex="1">
<td class="FirstRow FirstCol SCXW13188931 BCX8" role="rowheader" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">Prize</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:2,&quot;335551620&quot;:2,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="FirstRow SCXW13188931 BCX8" role="columnheader" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">Reward</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:2,&quot;335551620&quot;:2,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="FirstRow LastCol SCXW13188931 BCX8" role="columnheader" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">Number of Winners</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:2,&quot;335551620&quot;:2,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
</tr>
<tr class="TableRow SCXW13188931 BCX8" role="row" aria-rowindex="2">
<td class="FirstCol SCXW13188931 BCX8" role="rowheader" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">1</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">1,000,000 VND</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="LastCol SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">1</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
</tr>
<tr class="TableRow SCXW13188931 BCX8" role="row" aria-rowindex="3">
<td class="FirstCol SCXW13188931 BCX8" role="rowheader" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">2</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">500,000 VND</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="LastCol SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">6</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
</tr>
<tr class="TableRow SCXW13188931 BCX8" role="row" aria-rowindex="4">
<td class="FirstCol SCXW13188931 BCX8" role="rowheader" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">3</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">100,000 VND</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="LastCol SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">60</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
</tr>
<tr class="TableRow SCXW13188931 BCX8" role="row" aria-rowindex="5">
<td class="FirstCol SCXW13188931 BCX8" role="rowheader" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">4</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">10,000 VND</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="LastCol SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">300</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
</tr>
<tr class="TableRow SCXW13188931 BCX8" role="row" aria-rowindex="6">
<td class="FirstCol LastRow SCXW13188931 BCX8" role="rowheader" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">5</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="LastRow SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">5,000 VND</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="LastCol LastRow SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">All other participants</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
</tr>
</tbody>
</table>
</div>
</div>
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="TextRun SCXW13188931 BCX8" lang="EN-US" xml:lang="EN-US" data-contrast="auto"><span class="NormalTextRun SCXW13188931 BCX8">Please answer carefully; your participation is </span><span class="NormalTextRun SCXW13188931 BCX8">greatly appreciated</span><span class="NormalTextRun SCXW13188931 BCX8">.</span></span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:true,&quot;335551550&quot;:2,&quot;335551620&quot;:2,&quot;335559738&quot;:240,&quot;335559739&quot;:240}">&nbsp;</span></p>
</div>
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">Note:</span><span class="TextRun SCXW13188931 BCX8" lang="EN-US" xml:lang="EN-US" data-contrast="auto"><span class="NormalTextRun SCXW13188931 BCX8"> Due to limited </span><span class="NormalTextRun SCXW13188931 BCX8">capacity</span><span class="NormalTextRun SCXW13188931 BCX8">, </span><span class="NormalTextRun SCXW13188931 BCX8">we'll</span><span class="NormalTextRun SCXW13188931 BCX8"> evaluate responses to </span><span class="NormalTextRun SCXW13188931 BCX8">determine</span><span class="NormalTextRun SCXW13188931 BCX8"> eligibility. Not all may qualify, but we value your participation and may invite you to future activities. Each invited user can only </span><span class="NormalTextRun SCXW13188931 BCX8">participate</span><span class="NormalTextRun SCXW13188931 BCX8"> once.</span></span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:true,&quot;335551550&quot;:2,&quot;335551620&quot;:2,&quot;335559738&quot;:240,&quot;335559739&quot;:240}">&nbsp;</span></p>
</div>
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">Sample Survey Groups:</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:true,&quot;335551550&quot;:2,&quot;335551620&quot;:2,&quot;335559738&quot;:240,&quot;335559739&quot;:240}">&nbsp;</span></p>
</div>
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<div class="TableContainer Ltr SCXW13188931 BCX8">
<div id="{bb0038c9-4329-4b90-86ef-785e530c4437}{64}" class="WACAltTextDescribedBy SCXW13188931 BCX8" aria-hidden="true"></div>
<table class="Table Ltr TableWordWrap SCXW13188931 BCX8" border="1" data-tablestyle="MsoNormalTable" data-tablelook="1696" aria-rowcount="6">
<tbody class="SCXW13188931 BCX8">
<tr class="TableRow SCXW13188931 BCX8" role="row" aria-rowindex="1">
<td class="FirstRow FirstCol SCXW13188931 BCX8" role="rowheader" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">Gender</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:2,&quot;335551620&quot;:2,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="FirstRow SCXW13188931 BCX8" role="columnheader" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">Age</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:2,&quot;335551620&quot;:2,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="FirstRow SCXW13188931 BCX8" role="columnheader" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">Hanoi</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:2,&quot;335551620&quot;:2,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="FirstRow SCXW13188931 BCX8" role="columnheader" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">HCMC</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:2,&quot;335551620&quot;:2,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="FirstRow LastCol SCXW13188931 BCX8" role="columnheader" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">Total</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:2,&quot;335551620&quot;:2,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
</tr>
<tr class="TableRow SCXW13188931 BCX8" role="row" aria-rowindex="2">
<td class="FirstCol SCXW13188931 BCX8" role="rowheader" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">Male</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">15~29</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">150</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">150</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="LastCol SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">300</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
</tr>
<tr class="TableRow SCXW13188931 BCX8" role="row" aria-rowindex="3">
<td class="FirstCol SCXW13188931 BCX8" role="rowheader" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">Male</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">30~44</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">150</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">150</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="LastCol SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">300</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
</tr>
<tr class="TableRow SCXW13188931 BCX8" role="row" aria-rowindex="4">
<td class="FirstCol SCXW13188931 BCX8" role="rowheader" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">Female</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">15~29</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">150</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">150</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="LastCol SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">300</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
</tr>
<tr class="TableRow SCXW13188931 BCX8" role="row" aria-rowindex="5">
<td class="FirstCol SCXW13188931 BCX8" role="rowheader" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">Female</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">30~44</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">150</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">150</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="LastCol SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">300</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
</tr>
<tr class="TableRow SCXW13188931 BCX8" role="row" aria-rowindex="6">
<td class="FirstCol LastRow SCXW13188931 BCX8" role="rowheader" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">Total</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="LastRow SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="LastRow SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">600</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="LastRow SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">600</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
<td class="LastCol LastRow SCXW13188931 BCX8" data-celllook="0">
<div class="TableCellContent SCXW13188931 BCX8">
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">1,200</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</div>
</div>
</td>
</tr>
</tbody>
</table>
</div>
</div>
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">Limited spots&mdash;join now!</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:true,&quot;335551550&quot;:2,&quot;335551620&quot;:2,&quot;335559738&quot;:240,&quot;335559739&quot;:240}">&nbsp;</span></p>
</div>
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="NormalTextRun SCXW13188931 BCX8">Survey Criteria:</span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:true,&quot;335551550&quot;:2,&quot;335551620&quot;:2,&quot;335559738&quot;:240,&quot;335559739&quot;:240}">&nbsp;</span></p>
</div>
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="TextRun SCXW13188931 BCX8" lang="EN-US" xml:lang="EN-US" data-contrast="auto"><span class="NormalTextRun SCXW13188931 BCX8">Participants aged 15-44 </span><span class="NormalTextRun SCXW13188931 BCX8">residing</span><span class="NormalTextRun SCXW13188931 BCX8"> in Hanoi or Ho Chi Minh City.</span></span><span class="EOP SCXW13188931 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:true,&quot;335551550&quot;:2,&quot;335551620&quot;:2,&quot;335559738&quot;:240,&quot;335559739&quot;:240}">&nbsp;</span></p>
</div>
<div class="OutlineElement Ltr SCXW13188931 BCX8">
<p class="Paragraph SCXW13188931 BCX8"><span class="TextRun SCXW13188931 BCX8" lang="EN-US" xml:lang="EN-US" data-contrast="auto"><span class="NormalTextRun SCXW13188931 BCX8">We prioritize your privacy. By </span><span class="NormalTextRun SCXW13188931 BCX8">participating</span><span class="NormalTextRun SCXW13188931 BCX8">, you agree to our Privacy Policy. Please review it carefully before starting. If you disagree, please do not </span><span class="NormalTextRun SCXW13188931 BCX8">proceed</span><span class="NormalTextRun SCXW13188931 BCX8"> with the survey.</span></span></p>
</div>`

const PrivacyPolicy = `<div class="SCXW266454869 BCX8">
<div class="OutlineElement Ltr SCXW266454869 BCX8">
<p class="Paragraph SCXW266454869 BCX8"><span class="TextRun SCXW266454869 BCX8" lang="EN-US" xml:lang="EN-US" data-contrast="auto"><span class="NormalTextRun SCXW266454869 BCX8">Hello! Thank you for </span><span class="NormalTextRun SCXW266454869 BCX8">participating</span><span class="NormalTextRun SCXW266454869 BCX8"> in M-</span><span class="NormalTextRun SpellingErrorV2Themed SCXW266454869 BCX8">telligent</span><span class="NormalTextRun SCXW266454869 BCX8"> Solutions' online survey. This project is conducted by </span><span class="NormalTextRun SpellingErrorV2Themed SCXW266454869 BCX8">Hakuhodo</span><span class="NormalTextRun SCXW266454869 BCX8"> DY Holdings Inc. (HDY) and its affiliates, including </span><span class="NormalTextRun SpellingErrorV2Themed SCXW266454869 BCX8">Hakuhodo</span><span class="NormalTextRun SCXW266454869 BCX8"> Digital Vietnam Co., Ltd. (H-DIGITAL), collectively referred to as the "HDY Group." For details, visit: </span></span><a class="Hyperlink SCXW266454869 BCX8" href="https://www.hakuhodody-holdings.co.jp/group/about/group.html" target="_blank" rel="noreferrer noopener"><span class="NormalTextRun SCXW266454869 BCX8" data-ccp-charstyle="Hyperlink">HDY Group</span></a><span class="TextRun SCXW266454869 BCX8" lang="EN-US" xml:lang="EN-US" data-contrast="auto"><span class="NormalTextRun SCXW266454869 BCX8">. We </span><span class="NormalTextRun SCXW266454869 BCX8">are authorized to</span><span class="NormalTextRun SCXW266454869 BCX8"> conduct this survey on their behalf.</span></span><span class="EOP SCXW266454869 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:240,&quot;335559739&quot;:240}">&nbsp;</span></p>
</div>
<div class="OutlineElement Ltr SCXW266454869 BCX8">
<p class="Paragraph SCXW266454869 BCX8"><span class="NormalTextRun SCXW266454869 BCX8">Data Handling:</span><span class="EOP SCXW266454869 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:240,&quot;335559739&quot;:240}">&nbsp;</span></p>
</div>
<div class="ListContainerWrapper SCXW266454869 BCX8">
<ul class="BulletListStyle1 SCXW266454869 BCX8" role="list">
<li class="OutlineElement Ltr SCXW266454869 BCX8" role="listitem" data-leveltext="" data-font="Symbol" data-listid="1" data-list-defn-props="{&quot;335552541&quot;:1,&quot;335559685&quot;:720,&quot;335559991&quot;:360,&quot;469769226&quot;:&quot;Symbol&quot;,&quot;469769242&quot;:[8226],&quot;469777803&quot;:&quot;left&quot;,&quot;469777804&quot;:&quot;&quot;,&quot;469777815&quot;:&quot;multilevel&quot;}" aria-setsize="-1" data-aria-posinset="3" data-aria-level="1">
<p class="Paragraph SCXW266454869 BCX8"><span class="NormalTextRun SCXW266454869 BCX8">Programming, Storage &amp; Processing</span><span class="TextRun SCXW266454869 BCX8" lang="EN-US" xml:lang="EN-US" data-contrast="auto"><span class="NormalTextRun SCXW266454869 BCX8"> (excluding personal data): M-</span><span class="NormalTextRun SpellingErrorV2Themed SCXW266454869 BCX8">telligent</span><span class="NormalTextRun SCXW266454869 BCX8"> Solutions Limited, Hong Kong.</span></span><span class="EOP SCXW266454869 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</li>
</ul>
</div>
<div class="ListContainerWrapper SCXW266454869 BCX8">
<ul class="BulletListStyle1 SCXW266454869 BCX8" role="list">
<li class="OutlineElement Ltr SCXW266454869 BCX8" role="listitem" data-leveltext="" data-font="Symbol" data-listid="1" data-list-defn-props="{&quot;335552541&quot;:1,&quot;335559685&quot;:720,&quot;335559991&quot;:360,&quot;469769226&quot;:&quot;Symbol&quot;,&quot;469769242&quot;:[8226],&quot;469777803&quot;:&quot;left&quot;,&quot;469777804&quot;:&quot;&quot;,&quot;469777815&quot;:&quot;multilevel&quot;}" aria-setsize="-1" data-aria-posinset="4" data-aria-level="1">
<p class="Paragraph SCXW266454869 BCX8"><span class="NormalTextRun SCXW266454869 BCX8">Collection &amp; Processing</span><span class="NormalTextRun SCXW266454869 BCX8"> (including personal data): Indochina Research (Vietnam) Ltd.</span><span class="EOP SCXW266454869 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</li>
</ul>
</div>
<div class="OutlineElement Ltr SCXW266454869 BCX8">
<p class="Paragraph SCXW266454869 BCX8"><span class="NormalTextRun SCXW266454869 BCX8">Privacy Policy:</span><span class="TextRun SCXW266454869 BCX8" lang="EN-US" xml:lang="EN-US" data-contrast="auto"><span class="NormalTextRun SCXW266454869 BCX8"> This Policy explains how we handle your personal data. Please read it carefully before completing the survey. By clicking "I agree," you consent to our processing your data per this Policy. If you continue with the survey after updates to the Policy, we will consider it as your agreement to the updated terms. If you disagree, please do not </span><span class="NormalTextRun SCXW266454869 BCX8">participate</span><span class="NormalTextRun SCXW266454869 BCX8"> in the survey. We will not </span><span class="NormalTextRun SCXW266454869 BCX8">notify you</span><span class="NormalTextRun SCXW266454869 BCX8"> of Policy changes individually.</span></span><span class="EOP SCXW266454869 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:240,&quot;335559739&quot;:240}">&nbsp;</span></p>
</div>
<div class="OutlineElement Ltr SCXW266454869 BCX8">
<p class="Paragraph SCXW266454869 BCX8"><span class="NormalTextRun SCXW266454869 BCX8">Scope:</span><span class="EOP SCXW266454869 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:240,&quot;335559739&quot;:240}">&nbsp;</span></p>
</div>
<div class="ListContainerWrapper SCXW266454869 BCX8">
<ul class="BulletListStyle1 SCXW266454869 BCX8" role="list">
<li class="OutlineElement Ltr SCXW266454869 BCX8" role="listitem" data-leveltext="" data-font="Symbol" data-listid="3" data-list-defn-props="{&quot;335552541&quot;:1,&quot;335559685&quot;:720,&quot;335559991&quot;:360,&quot;469769226&quot;:&quot;Symbol&quot;,&quot;469769242&quot;:[8226],&quot;469777803&quot;:&quot;left&quot;,&quot;469777804&quot;:&quot;&quot;,&quot;469777815&quot;:&quot;hybridMultilevel&quot;}" aria-setsize="-1" data-aria-posinset="1" data-aria-level="1">
<p class="Paragraph SCXW266454869 BCX8"><span class="TextRun SCXW266454869 BCX8" lang="EN-US" xml:lang="EN-US" data-contrast="auto"><span class="NormalTextRun SCXW266454869 BCX8">Personal data processed may include information provided by </span><span class="NormalTextRun SpellingErrorV2Themed SCXW266454869 BCX8">MoMo</span><span class="NormalTextRun SCXW266454869 BCX8">, survey data, and any data you voluntarily provide.</span></span><span class="EOP SCXW266454869 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</li>
</ul>
</div>
<div class="ListContainerWrapper SCXW266454869 BCX8">
<ul class="BulletListStyle1 SCXW266454869 BCX8" role="list">
<li class="OutlineElement Ltr SCXW266454869 BCX8" role="listitem" data-leveltext="" data-font="Symbol" data-listid="3" data-list-defn-props="{&quot;335552541&quot;:1,&quot;335559685&quot;:720,&quot;335559991&quot;:360,&quot;469769226&quot;:&quot;Symbol&quot;,&quot;469769242&quot;:[8226],&quot;469777803&quot;:&quot;left&quot;,&quot;469777804&quot;:&quot;&quot;,&quot;469777815&quot;:&quot;hybridMultilevel&quot;}" aria-setsize="-1" data-aria-posinset="2" data-aria-level="1">
<p class="Paragraph SCXW266454869 BCX8"><span class="NormalTextRun SCXW266454869 BCX8">We do not process sensitive personal data such as medical or criminal records.</span><span class="EOP SCXW266454869 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</li>
</ul>
</div>
<div class="ListContainerWrapper SCXW266454869 BCX8">
<ul class="BulletListStyle1 SCXW266454869 BCX8" role="list">
<li class="OutlineElement Ltr SCXW266454869 BCX8" role="listitem" data-leveltext="" data-font="Symbol" data-listid="3" data-list-defn-props="{&quot;335552541&quot;:1,&quot;335559685&quot;:720,&quot;335559991&quot;:360,&quot;469769226&quot;:&quot;Symbol&quot;,&quot;469769242&quot;:[8226],&quot;469777803&quot;:&quot;left&quot;,&quot;469777804&quot;:&quot;&quot;,&quot;469777815&quot;:&quot;hybridMultilevel&quot;}" aria-setsize="-1" data-aria-posinset="3" data-aria-level="1">
<p class="Paragraph SCXW266454869 BCX8"><span class="NormalTextRun SCXW266454869 BCX8">This Policy does not apply to third-party websites, even if linked by us.</span><span class="EOP SCXW266454869 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</li>
</ul>
</div>
<div class="OutlineElement Ltr SCXW266454869 BCX8">
<p class="Paragraph SCXW266454869 BCX8"><span class="NormalTextRun SCXW266454869 BCX8">Purpose of Data Collection:</span><span class="EOP SCXW266454869 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:240,&quot;335559739&quot;:240}">&nbsp;</span></p>
</div>
<div class="ListContainerWrapper SCXW266454869 BCX8">
<ul class="BulletListStyle1 SCXW266454869 BCX8" role="list">
<li class="OutlineElement Ltr SCXW266454869 BCX8" role="listitem" data-leveltext="" data-font="Symbol" data-listid="4" data-list-defn-props="{&quot;335552541&quot;:1,&quot;335559685&quot;:720,&quot;335559991&quot;:360,&quot;469769226&quot;:&quot;Symbol&quot;,&quot;469769242&quot;:[8226],&quot;469777803&quot;:&quot;left&quot;,&quot;469777804&quot;:&quot;&quot;,&quot;469777815&quot;:&quot;hybridMultilevel&quot;}" aria-setsize="-1" data-aria-posinset="1" data-aria-level="1">
<p class="Paragraph SCXW266454869 BCX8"><span class="NormalTextRun SCXW266454869 BCX8">Issuing rewards to survey participants.</span><span class="EOP SCXW266454869 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</li>
</ul>
</div>
<div class="ListContainerWrapper SCXW266454869 BCX8">
<ul class="BulletListStyle1 SCXW266454869 BCX8" role="list">
<li class="OutlineElement Ltr SCXW266454869 BCX8" role="listitem" data-leveltext="" data-font="Symbol" data-listid="4" data-list-defn-props="{&quot;335552541&quot;:1,&quot;335559685&quot;:720,&quot;335559991&quot;:360,&quot;469769226&quot;:&quot;Symbol&quot;,&quot;469769242&quot;:[8226],&quot;469777803&quot;:&quot;left&quot;,&quot;469777804&quot;:&quot;&quot;,&quot;469777815&quot;:&quot;hybridMultilevel&quot;}" aria-setsize="-1" data-aria-posinset="2" data-aria-level="1">
<p class="Paragraph SCXW266454869 BCX8"><span class="NormalTextRun SCXW266454869 BCX8">Conducting research and market analysis.</span><span class="EOP SCXW266454869 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</li>
</ul>
</div>
<div class="ListContainerWrapper SCXW266454869 BCX8">
<ul class="BulletListStyle1 SCXW266454869 BCX8" role="list">
<li class="OutlineElement Ltr SCXW266454869 BCX8" role="listitem" data-leveltext="" data-font="Symbol" data-listid="4" data-list-defn-props="{&quot;335552541&quot;:1,&quot;335559685&quot;:720,&quot;335559991&quot;:360,&quot;469769226&quot;:&quot;Symbol&quot;,&quot;469769242&quot;:[8226],&quot;469777803&quot;:&quot;left&quot;,&quot;469777804&quot;:&quot;&quot;,&quot;469777815&quot;:&quot;hybridMultilevel&quot;}" aria-setsize="-1" data-aria-posinset="3" data-aria-level="1">
<p class="Paragraph SCXW266454869 BCX8"><span class="NormalTextRun SCXW266454869 BCX8">Advertising and marketing for HDY Group or clients.</span><span class="EOP SCXW266454869 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</li>
</ul>
</div>
<div class="ListContainerWrapper SCXW266454869 BCX8">
<ul class="BulletListStyle1 SCXW266454869 BCX8" role="list">
<li class="OutlineElement Ltr SCXW266454869 BCX8" role="listitem" data-leveltext="" data-font="Symbol" data-listid="4" data-list-defn-props="{&quot;335552541&quot;:1,&quot;335559685&quot;:720,&quot;335559991&quot;:360,&quot;469769226&quot;:&quot;Symbol&quot;,&quot;469769242&quot;:[8226],&quot;469777803&quot;:&quot;left&quot;,&quot;469777804&quot;:&quot;&quot;,&quot;469777815&quot;:&quot;hybridMultilevel&quot;}" aria-setsize="-1" data-aria-posinset="4" data-aria-level="1">
<p class="Paragraph SCXW266454869 BCX8"><span class="NormalTextRun SCXW266454869 BCX8">Providing consultation and related services.</span><span class="EOP SCXW266454869 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</li>
</ul>
</div>
<div class="OutlineElement Ltr SCXW266454869 BCX8">
<p class="Paragraph SCXW266454869 BCX8"><span class="NormalTextRun SCXW266454869 BCX8">Data Use:</span><span class="EOP SCXW266454869 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:240,&quot;335559739&quot;:240}">&nbsp;</span></p>
</div>
<div class="ListContainerWrapper SCXW266454869 BCX8">
<ul class="BulletListStyle1 SCXW266454869 BCX8" role="list">
<li class="OutlineElement Ltr SCXW266454869 BCX8" role="listitem" data-leveltext="" data-font="Symbol" data-listid="5" data-list-defn-props="{&quot;335552541&quot;:1,&quot;335559685&quot;:720,&quot;335559991&quot;:360,&quot;469769226&quot;:&quot;Symbol&quot;,&quot;469769242&quot;:[8226],&quot;469777803&quot;:&quot;left&quot;,&quot;469777804&quot;:&quot;&quot;,&quot;469777815&quot;:&quot;hybridMultilevel&quot;}" aria-setsize="-1" data-aria-posinset="1" data-aria-level="1">
<p class="Paragraph SCXW266454869 BCX8"><span class="NormalTextRun SCXW266454869 BCX8">Time:</span><span class="NormalTextRun SCXW266454869 BCX8"> During the retention period for this project.</span><span class="EOP SCXW266454869 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</li>
</ul>
</div>
</div>
<div class="SCXW266454869 BCX8">
<div class="ListContainerWrapper SCXW266454869 BCX8">
<ul class="BulletListStyle1 SCXW266454869 BCX8" role="list">
<li class="OutlineElement Ltr SCXW266454869 BCX8" role="listitem" data-leveltext="" data-font="Symbol" data-listid="5" data-list-defn-props="{&quot;335552541&quot;:1,&quot;335559685&quot;:720,&quot;335559991&quot;:360,&quot;469769226&quot;:&quot;Symbol&quot;,&quot;469769242&quot;:[8226],&quot;469777803&quot;:&quot;left&quot;,&quot;469777804&quot;:&quot;&quot;,&quot;469777815&quot;:&quot;hybridMultilevel&quot;}" aria-setsize="-1" data-aria-posinset="2" data-aria-level="1">
<p class="Paragraph SCXW266454869 BCX8"><span class="NormalTextRun SCXW266454869 BCX8">Area:</span><span class="NormalTextRun SCXW266454869 BCX8"> Survey results may be used where we provide services, including Vietnam and Japan.</span><span class="EOP SCXW266454869 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</li>
</ul>
</div>
<div class="ListContainerWrapper SCXW266454869 BCX8">
<ul class="BulletListStyle1 SCXW266454869 BCX8" role="list">
<li class="OutlineElement Ltr SCXW266454869 BCX8" role="listitem" data-leveltext="" data-font="Symbol" data-listid="5" data-list-defn-props="{&quot;335552541&quot;:1,&quot;335559685&quot;:720,&quot;335559991&quot;:360,&quot;469769226&quot;:&quot;Symbol&quot;,&quot;469769242&quot;:[8226],&quot;469777803&quot;:&quot;left&quot;,&quot;469777804&quot;:&quot;&quot;,&quot;469777815&quot;:&quot;hybridMultilevel&quot;}" aria-setsize="-1" data-aria-posinset="3" data-aria-level="1">
<p class="Paragraph SCXW266454869 BCX8"><span class="NormalTextRun SCXW266454869 BCX8">Recipients:</span><span class="TextRun SCXW266454869 BCX8" lang="EN-US" xml:lang="EN-US" data-contrast="auto"><span class="NormalTextRun SCXW266454869 BCX8"> We </span><span class="NormalTextRun SCXW266454869 BCX8">won&rsquo;t</span> <span class="NormalTextRun SCXW266454869 BCX8">disclose</span><span class="NormalTextRun SCXW266454869 BCX8"> your data to third parties without your consent unless required by law.</span></span><span class="EOP SCXW266454869 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</li>
</ul>
</div>
<div class="ListContainerWrapper SCXW266454869 BCX8">
<ul class="BulletListStyle1 SCXW266454869 BCX8" role="list">
<li class="OutlineElement Ltr SCXW266454869 BCX8" role="listitem" data-leveltext="" data-font="Symbol" data-listid="5" data-list-defn-props="{&quot;335552541&quot;:1,&quot;335559685&quot;:720,&quot;335559991&quot;:360,&quot;469769226&quot;:&quot;Symbol&quot;,&quot;469769242&quot;:[8226],&quot;469777803&quot;:&quot;left&quot;,&quot;469777804&quot;:&quot;&quot;,&quot;469777815&quot;:&quot;hybridMultilevel&quot;}" aria-setsize="-1" data-aria-posinset="4" data-aria-level="1">
<p class="Paragraph SCXW266454869 BCX8"><span class="NormalTextRun SCXW266454869 BCX8">Methods:</span><span class="NormalTextRun SCXW266454869 BCX8"> Data will be analyzed and provided as Statistical Data, which does not include personal identifiers.</span><span class="EOP SCXW266454869 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</li>
</ul>
</div>
<div class="OutlineElement Ltr SCXW266454869 BCX8">
<p class="Paragraph SCXW266454869 BCX8"><span class="NormalTextRun SCXW266454869 BCX8">Storage &amp; Retention:</span><span class="NormalTextRun SCXW266454869 BCX8"> Your data will be stored in Vietnam under strict security measures.</span><span class="EOP SCXW266454869 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:240,&quot;335559739&quot;:240}">&nbsp;</span></p>
</div>
<div class="OutlineElement Ltr SCXW266454869 BCX8">
<p class="Paragraph SCXW266454869 BCX8"><span class="NormalTextRun SCXW266454869 BCX8">Your Rights:</span><span class="EOP SCXW266454869 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:240,&quot;335559739&quot;:240}">&nbsp;</span></p>
</div>
<div class="ListContainerWrapper SCXW266454869 BCX8">
<ul class="BulletListStyle1 SCXW266454869 BCX8" role="list">
<li class="OutlineElement Ltr SCXW266454869 BCX8" role="listitem" data-leveltext="" data-font="Symbol" data-listid="6" data-list-defn-props="{&quot;335552541&quot;:1,&quot;335559685&quot;:720,&quot;335559991&quot;:360,&quot;469769226&quot;:&quot;Symbol&quot;,&quot;469769242&quot;:[8226],&quot;469777803&quot;:&quot;left&quot;,&quot;469777804&quot;:&quot;&quot;,&quot;469777815&quot;:&quot;hybridMultilevel&quot;}" aria-setsize="-1" data-aria-posinset="1" data-aria-level="1">
<p class="Paragraph SCXW266454869 BCX8"><span class="NormalTextRun SCXW266454869 BCX8">Request information or copies of your personal data.</span><span class="EOP SCXW266454869 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</li>
</ul>
</div>
<div class="ListContainerWrapper SCXW266454869 BCX8">
<ul class="BulletListStyle1 SCXW266454869 BCX8" role="list">
<li class="OutlineElement Ltr SCXW266454869 BCX8" role="listitem" data-leveltext="" data-font="Symbol" data-listid="6" data-list-defn-props="{&quot;335552541&quot;:1,&quot;335559685&quot;:720,&quot;335559991&quot;:360,&quot;469769226&quot;:&quot;Symbol&quot;,&quot;469769242&quot;:[8226],&quot;469777803&quot;:&quot;left&quot;,&quot;469777804&quot;:&quot;&quot;,&quot;469777815&quot;:&quot;hybridMultilevel&quot;}" aria-setsize="-1" data-aria-posinset="2" data-aria-level="1">
<p class="Paragraph SCXW266454869 BCX8"><span class="NormalTextRun SCXW266454869 BCX8">Request corrections or deletions.</span><span class="EOP SCXW266454869 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</li>
</ul>
</div>
<div class="ListContainerWrapper SCXW266454869 BCX8">
<ul class="BulletListStyle1 SCXW266454869 BCX8" role="list">
<li class="OutlineElement Ltr SCXW266454869 BCX8" role="listitem" data-leveltext="" data-font="Symbol" data-listid="6" data-list-defn-props="{&quot;335552541&quot;:1,&quot;335559685&quot;:720,&quot;335559991&quot;:360,&quot;469769226&quot;:&quot;Symbol&quot;,&quot;469769242&quot;:[8226],&quot;469777803&quot;:&quot;left&quot;,&quot;469777804&quot;:&quot;&quot;,&quot;469777815&quot;:&quot;hybridMultilevel&quot;}" aria-setsize="-1" data-aria-posinset="3" data-aria-level="1">
<p class="Paragraph SCXW266454869 BCX8"><span class="NormalTextRun SCXW266454869 BCX8">Refuse to receive promotional messages. To exercise these rights, contact our service center at </span><a class="Hyperlink SCXW266454869 BCX8" href="mailto:dpo.vietnam@indochinaresearch.com" target="_blank" rel="noreferrer noopener"><span class="NormalTextRun SCXW266454869 BCX8" data-ccp-charstyle="Hyperlink">dpo.vietnam@indochinaresearch.com</span></a><span class="NormalTextRun SCXW266454869 BCX8">.</span><span class="EOP SCXW266454869 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:0,&quot;335559739&quot;:0}">&nbsp;</span></p>
</li>
</ul>
</div>
<div class="OutlineElement Ltr SCXW266454869 BCX8">
<p class="Paragraph SCXW266454869 BCX8"><span class="NormalTextRun SCXW266454869 BCX8">Cookies:</span><span class="TextRun SCXW266454869 BCX8" lang="EN-US" xml:lang="EN-US" data-contrast="auto"><span class="NormalTextRun SCXW266454869 BCX8"> We use cookies to </span><span class="NormalTextRun SCXW266454869 BCX8">optimize</span><span class="NormalTextRun SCXW266454869 BCX8"> your experience and analyze website usage. You can adjust your browser settings to manage cookies, but some features may be limited.</span></span><span class="EOP SCXW266454869 BCX8" data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;134245417&quot;:false,&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:240,&quot;335559739&quot;:240}">&nbsp;</span></p>
</div>
<div class="OutlineElement Ltr SCXW266454869 BCX8">
<p class="Paragraph SCXW266454869 BCX8"><span class="NormalTextRun SCXW266454869 BCX8">Impact of Not Providing Data:</span><span class="NormalTextRun SCXW266454869 BCX8"> Participation in the survey and receiving rewards requires providing your personal data.</span></p>
</div>
</div>`

export { AgreementMessage, ActivityRules, PrivacyPolicy }