// import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Col, Row, Input, Form, FormFeedback, Button, Spinner, Table } from "reactstrap";
import { Label, InputGroup, Card, CardBody, CardHeader } from "reactstrap";
import * as Yup from "yup";
import { useFormik, FieldArray, FormikProvider } from "formik";
import Select from "react-select"
import { withTranslation } from "react-i18next";
import '../../components/Common/ts-css.css'
import fn_helper from "helpers/fn_helper";
import { ReactSortable } from "react-sortablejs";
import useQuestionnaireStore from "../../zustand/store/QuestionnaireModule";

const DisplayCriteria = ({
    idx = 0,
    rowData = {},
    rowsLength = 0,
    onChangeInput = () => console.log('onChangeInput'),
    onMove = (action, index) => console.log('onMove')
}) => {
    // CENTER STATE
    const { questionSet = [] } = useQuestionnaireStore(state => state); // STATE
    const { setQuestionSet } = useQuestionnaireStore(); // ACTION

    const ddSelect = [...Array(6)].map((item, i) => ({ id: `${i}`, name: `${i}` }))

    const mapText = { 'openend': 'Open End', 'image': 'Image' }

    const onRemoveRow = (_index = -1) => {
        console.log("🚀 ~ onRemoveRow ~ _index:", _index)
        let tmpRows = _.cloneDeep(questionSet)
        tmpRows = tmpRows.filter((item, i) => i !== _index)
        console.log("🚀 ~ onRemoveRow ~ tmpRows:", tmpRows)
        setQuestionSet(tmpRows)

    }
    const onChangeInputOne = (_field = '', _index = -1, _value ) => {
        console.log("🚀 ~ onChangeInputOne ~ _field:", _field)
        console.log("🚀 ~ onChangeInputOne ~ _index:", _index)
        console.log("🚀 ~ onChangeInputOne ~ _value:", _value)
        let tmpRows = _.cloneDeep(questionSet)
        tmpRows[_index][_field] = _value
        console.log("🚀 ~ onChangeInputOne ~ tmpRows:", tmpRows)
        setQuestionSet(tmpRows)

    }
    const onChangeMutiInput = (_field = [], _index = -1, _value = [] ) => {
        console.log("🚀 ~ onChangeMutiInput ~ _field:", _field)
        console.log("🚀 ~ onChangeMutiInput ~ _index:", _index)
        console.log("🚀 ~ onChangeMutiInput ~ _value:", _value)
        let tmpRows = _.cloneDeep(questionSet)
        for (let i in _field) {
            const field = _field[i]
            const value = _value[i]
            tmpRows[_index][field] = value
        }
        console.log("🚀 ~ onChangeMutiInput ~ tmpRows:", tmpRows)
        setQuestionSet(tmpRows)
    }
    
    const index = idx;

    const ddGoto = fn_helper.OPTIONS.GOTO(rowsLength, index)

    return(
        <Row key={idx} className="sortable">
            <Col>
                <Card className="bg-secondary-subtle">
                    <CardHeader className="bg-transparent fs-6 text">
                        <Row>
                            <Col>
                                <div className="d-flex align-item-center gap-1">
                                    <i className='bx bx-grid-vertical handle' style={{cursor: "grab", fontSize: '20px'}}></i> 
                                    <div>
                                        {
                                            index > 0 && (
                                                <i 
                                                    className='bx bx-caret-up' 
                                                    style={{ cursor: 'pointer', fontSize: '20px' }}
                                                    onClick={() => onMove('UP', idx)}
                                                ></i>
                                            )
                                        }
                                        {
                                            (rowsLength - 1) > index && (
                                                <i 
                                                    className='bx bx-caret-down' 
                                                    style={{ cursor: 'pointer',  fontSize: '20px' }}
                                                    onClick={() => onMove('DOWN', idx)}
                                                ></i>
                                            )
                                        }
                                    </div>
                                    <span className="align-middle">{mapText[rowData.question_type] ?? '-'}</span>
                                </div>
                            </Col>
                            <Col>
                                <button
                                    key={idx}
                                    type="button"
                                    className="btn-close align-middle"
                                    style={{position: 'absolute', right: 0}}
                                    aria-label="Close"
                                    onClick={() => {
                                        onRemoveRow(index)
                                    }}
                                />
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row className="mb-3">
                            <Col>
                                <Label
                                    htmlFor="example-search-input"
                                    className="col-form-label"
                                    >
                                    Question {(idx + 1)}
                                </Label>
                                <div>
                                    <Input
                                        className="form-control mw-100"
                                        type="text"
                                        name="question_text"
                                        placeholder="eg. What do you do?"
                                        value={rowData.question_text}
                                        onChange={(el) => {
                                            onChangeInputOne('question_text', index, el.target.value)
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <Row className="row-cols-md-auto g-3 align-items-center mb-2">
                                    <Col>
                                        <div className="align-middle">
                                            <div className="form-check form-switch" >
                                                <Input
                                                    id={'ai_addon_questions_'+ index}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={rowData.ai_addon_questions}
                                                    onClick={(el) => {
                                                        const value = rowData.ai_addon_questions
                                                        onChangeInputOne('ai_addon_questions', index, !value)
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor={'ai_addon_questions_'+ index}
                                                >
                                                    AI add-on Questions
                                                </Label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="align-middle">
                                        <div className="">
                                            <Select
                                                placeholder="Select"
                                                value={fn_helper.FNFORM.getObjectValue(ddSelect, `${rowData.ask_number_question}`)}
                                                getOptionValue={(option) => option.id}
                                                getOptionLabel={(option) => option.name}
                                                onChange={(value) => {
                                                    const valueId = value.id
                                                    onChangeInputOne('ask_number_question', index, valueId)
                                                }}
                                                options={ddSelect}
                                                isDisabled={rowData.ai_addon_questions === false}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                            <Row className="row-cols-md-auto g-3 align-items-center d-flex justify-content-end">
                                    <Col>
                                        <span className="align-middle float-right">
                                            <Label htmlFor={`goto_` + index} className="col-form-label">Goto</Label>
                                        </span>
                                    </Col>
                                    <Col md={4}>
                                        <div className="align-middle">
                                            {/* {JSON.stringify(rowData.goto)} */}
                                            {
                                                rowData.goto 
                                                ? (
                                                    <Select
                                                        id={`goto_` + index}
                                                        placeholder="Select"
                                                        value={fn_helper.FNFORM.getObjectValue(ddGoto, `${rowData.goto}`)}
                                                        getOptionValue={(option) => option.id}
                                                        getOptionLabel={(option) => option.name}
                                                        onChange={(value) => {
                                                            const valueId = value.id
                                                            onChangeInputOne('goto', index, valueId)
                                                        }}
                                                        options={ddGoto}
                                                    />
                                                )
                                                : <></>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={6}>
                                <Row className="mb-3">
                                    <Col className="col-md-12">
                                        <div className="form-check form-switch" >
                                            <Input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={rowData.evaluation_criteria}
                                                onClick={(el) => {
                                                    const value = rowData.evaluation_criteria
                                                    onChangeInputOne('evaluation_criteria', index, !value)
                                                }}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="evaluation_criteria"
                                            >
                                                User’s Answer Evaluation Criteria
                                        </label>
                                        </div>
                                    </Col>
                                </Row>
                                {/* { evaluation_criteria && <> */}
                                    <Row className="mb-3">
                                        <Col className="col-md-1"></Col>
                                        <Col className="col-md-11">
                                            <Row>
                                                <label
                                                    htmlFor="relevance_score"
                                                    className="col-md-5 col-form-label"
                                                    >
                                                    Relevance Score (0-100%): <em className="fw-light">Scores how relevant the respondent's answer is to the question</em>
                                                </label>
                                                <div className="col-md-3">
                                                    <InputGroup>
                                                        <Input
                                                            className="form-control"
                                                            type="number"
                                                            value={rowData.relevance_score}
                                                            onInput={fn_helper.FNFORM.handleInputMin0Max100}
                                                            onChange={(el) => {
                                                                const valueUpdate = el.target.valueAsNumber
                                                                onChangeMutiInput(['relevance_score', 'detail_score'], index, [valueUpdate, 100 - valueUpdate])
                                                            }}
                                                            min={0}
                                                            max={100}
                                                        />
                                                        <span className="input-group-append">
                                                            <span className="input-group-text">%</span>
                                                        </span>
                                                    </InputGroup>
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col className="col-md-1"></Col>
                                        <Col className="col-md-11">
                                            <Row>
                                                <label
                                                    htmlFor="detail_score"
                                                    className="col-md-5 col-form-label"
                                                    >
                                                    Detail Score (0-100%): <em className="fw-light">Scores the level of detail in the respondent's answer</em>
                                                </label>
                                                <div className="col-md-3">
                                                    <InputGroup>
                                                        <Input
                                                            className="form-control"
                                                            type="number"
                                                            value={rowData.detail_score}
                                                            onInput={fn_helper.FNFORM.handleInputMin0Max100}
                                                            onChange={(el) => {
                                                                const valueUpdate = el.target.valueAsNumber
                                                                onChangeMutiInput(['detail_score', 'relevance_score'], index, [valueUpdate, 100 - valueUpdate])
                                                            }}
                                                            min={0}
                                                            max={100}
                                                        />
                                                        <span className="input-group-append">
                                                            <span className="input-group-text">%</span>
                                                        </span>
                                                    </InputGroup>
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col className="col-md-1"></Col>
                                        <Col className="col-md-11">
                                            <Row>
                                                <label
                                                    htmlFor="average_score"
                                                    className="col-md-5 col-form-label"
                                                    >
                                                    Average Score (1-10): <em className="fw-light">The minimum score needed to pass a question.</em>
                                                </label>
                                                <div className="col-md-3">
                                                    <Input
                                                        className="form-control"
                                                        type="number"
                                                        value={rowData.average_score}
                                                        onChange={(el) => {
                                                            onChangeInputOne('average_score', index, el.target.valueAsNumber)
                                                        }}
                                                        min={1}
                                                        max={10}
                                                    />
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}
export default withTranslation()(DisplayCriteria)