import React, { useEffect, useRef, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";


//Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

import { get } from 'helpers/api_helper'
import Spinners from "components/Common/Spinner";

import { isEmpty } from "lodash";
// import { useDispatch, useSelector } from "react-redux";
// import { createSelector } from "reselect";
// // import { getSidebarMenu as onGetSidebarMenu } from "store/layout/actions";

const SidebarContent = props => {
  // const dispatch = useDispatch();

  // const SidebarMenuProperties = createSelector(
  //   (state) => state.Layout,
  //   (Layout) => ({
  //     menuList: Layout.menuList,
  //     menuLoading: Layout.menuLoading,
  //     menuError: Layout.menuError
  //   })
  // );

  // const {
  //   menuList, menuLoading, menuError
  // } = useSelector(SidebarMenuProperties);

  // const [isMenuLoading, setMenuLoading] = useState(menuLoading)
  const [isMenuLoading, setMenuLoading] = useState(true)
  const [sidebarMenu, setSidebarMenu] = useState([]);

  // useEffect(() => {
  //   if(typeof menuList !== "undefined")
  //     console.log('menuList', menuList);
  // }, [menuList])

  // useEffect(() => {
  //     dispatch(onGetSidebarMenu());
  // }, [dispatch]);

  // useEffect(() => {
  //   if (!isEmpty(menuList)) setSidebarMenu(menuList);
  // }, [menuList]);

  useEffect(() => {
    const fetchMenu = async () => {
      const result = await get(process.env.REACT_APP_BACKEND_URL + '/v1/admin/menu/sidebar')
      setSidebarMenu(result);
    };

    fetchMenu();
  }, []);

  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    if(!isMenuLoading) {
      new MetisMenu("#side-menu");
      activeMenu();
      setMenuLoading(false)
    }
  }, [isMenuLoading]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {
              isMenuLoading ? <Spinners setLoading={setMenuLoading} />
                :
                <>
                  {
                    !isEmpty(sidebarMenu) && sidebarMenu.map((m, i) => {
                      if(typeof m.submenu == 'undefined') {
                        return <li key={i}><Link to={'/' + m.menu_link}><i className={'bx ' + m.menu_icon}></i><span>{m.menu_label}</span></Link></li>
                      }
                      else {
                          return (
                            <li key={i}>
                              <Link to="/#" className="has-arrow">
                                <i className={'bx ' + m.menu_icon}></i>
                                <span>{m.menu_label}</span>
                              </Link>
                              <ul className="sub-menu">
                                {
                                  m.submenu.map((s, is) => {
                                    return <li key={is}><Link to={'/' + s.menu_link}><i className={'bx ' + s.menu_icon}></i>{s.menu_label}</Link></li>
                                  })
                                }
                              </ul>
                            </li>
                          )
                      }
                    })
                  }
                </>
            }
            {/* {
              sidebarMenu.length > 0 && sidebarMenu.map((m, i) => {
                if(typeof m.submenu == 'undefined') {
                  return <li key={i}><Link to={'/' + m.menu_link}><i className={'bx ' + m.menu_icon}></i><span>{m.menu_label}</span></Link></li>
                }
                else {
                    return (
                      <li key={i}>
                        <Link to="/#" className="has-arrow">
                          <i className={'bx ' + m.menu_icon}></i>
                          <span>{m.menu_label}</span>
                        </Link>
                        <ul className="sub-menu">
                          {
                            m.submenu.map((s, is) => {
                              return <li key={is}><Link to={'/' + s.menu_link}><i className={'bx ' + s.menu_icon}></i>{s.menu_label}</Link></li>
                            })
                          }
                        </ul>
                      </li>
                    )
                }
              })
            } */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  // location: PropTypes.object,
  t: PropTypes.any,
  menuList: PropTypes.object,
  onGetSidebarMenu: PropTypes.func,
};

export default withRouter(withTranslation()(SidebarContent));
