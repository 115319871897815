import {
    UPDATE_QUESTIONAIRE_CONSENT,
    UPDATE_QUESTIONAIRE_CONSENT_SUCCESSFUL,
    UPDATE_QUESTIONAIRE_CONSENT_FAILED,
    UPDATE_QUESTIONAIRE_CONSENT_RESET,
} from "./actionsType"

export const updateQuestionaireConsent = questionData => {
    return {
        type: UPDATE_QUESTIONAIRE_CONSENT,
        payload: { questionData },
    }
}

export const updateQuestionaireConsentSuccessful = questionData => {
    return {
        type: UPDATE_QUESTIONAIRE_CONSENT_SUCCESSFUL,
        payload: questionData,
    }
}

export const updateQuestionaireConsentFailed = questionData => {
    return {
        type: UPDATE_QUESTIONAIRE_CONSENT_FAILED,
        payload: questionData,
    }
}

export const updateQuestionaireConsentReset = questionData => {
    return {
        type: UPDATE_QUESTIONAIRE_CONSENT_RESET,
        payload: { questionData },
    }
}
