import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { get, post, put } from "helpers/api_helper";

const TalkComponent = () => {
  const navigate = useNavigate()
  const { questionnaireUUID } = useParams();

  useEffect(() => {
    if (questionnaireUUID) {
      const v7 = () => {
        return 'tttttttt-tttt-7xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.trunc(Math.random() * 16);
            const v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        }).replace(/^[t]{8}-[t]{4}/, function () {
            const unixtimestamp = Date.now().toString(16).padStart(12, '0');
            return unixtimestamp.slice(0, 8) + '-' + unixtimestamp.slice(8);
        });
      }

      const chatId = v7()
      const queryString = window.location.search;
      console.log(queryString);
      const urlParams = new URLSearchParams(queryString);

      Talk.ready.then(function () {
          const me = new Talk.User({
              id: chatId,
              name: chatId,
              role: "default",
              photoUrl: "https://img5.pic.in.th/file/secure-sv1/8666609_user_icon.png",
          });

          const talkSession = new Talk.Session({
              appId: "t7KXPj1P", // replace with your TalkJS app ID
              me: me,
          });

          const bot = new Talk.User({
              id: "ai-shopping-bot",
              name: "AI Shopping Bot 🤖",
              role: "default",
              photoUrl: "https://img2.pic.in.th/pic/f256x256png.png",
              welcomeMessage: "Please type \"Start\" to start interview",
          });

          var conversation = talkSession.getOrCreateConversation(
              chatId
          );
          console.log("🚀 ~ chatId:", chatId)


          conversation.setParticipant(me);
          conversation.setParticipant(bot);
          conversation.setAttributes({
              custom: {
                  questionnaireId: questionnaireUUID,
                  phoneNumber: localStorage.getItem('phoneNumber')
              }
          });

          const inbox = talkSession.createChatbox();

          inbox.onCustomMessageAction('textToSpeech', (event) => {
            console.log('Action has been triggered!');
            console.log('event', event)

            const speakObj = new SpeechSynthesisUtterance();
            console.log('speakObj', speakObj)

            const allVoicesObtained = new Promise(function(resolve, reject) {
              let voices = window.speechSynthesis.getVoices();
              if (voices.length !== 0) {
                resolve(voices);
              } else {
                window.speechSynthesis.addEventListener("voiceschanged", function() {
                  voices = window.speechSynthesis.getVoices();
                  resolve(voices);
                });
              }
            });
            
            allVoicesObtained.then(voices => {
              // speakObj.voice = voices.filter(function(voice) { return voice.name == 'Kanya'; })[0];
              speakObj.text = `${event.message.body}`;
              speechSynthesis.speak(speakObj);
            });
          });

          inbox.select(conversation);
          inbox.mount(document.getElementById("talkjs-container"));
      });
    }

    return () => {
      // Cleanup script when the component is unmounted
      const script = document.querySelector('script[src="https://cdn.talkjs.com/talk.js"]');
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, [questionnaireUUID]);

  useEffect(()=> {
    const fetchContent = async () => {
    const result = await post(process.env.REACT_APP_BACKEND_URL + '/public/check-questionaire', {
      phone_number: localStorage.getItem('phoneNumber'),
      uuid_link: questionnaireUUID
    })
      console.log('result', result)
      if (result.status === '200') {
        if (result.data.length) {
          alert('This phone number has already taken the survey. Retaking is not available at this time.')
          navigate('/pdpa/chat/'+ questionnaireUUID)
        }
      }
    };

    fetchContent();
  },[])
  document.title = "Webchat"
  return <div id="talkjs-container" style={{ width: '100%', height: '100vh' }} />;
};

export default TalkComponent;
